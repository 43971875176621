import React from "react";
import tw from "twin.macro";
export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const navLinks = [
  <NavLinks key={1}>
    <NavLink href="/">
      <div class="dropdown inline-block ">
        <button class="font-semibold py-2 rounded inline-flex items-center">
          <span class="mr-1">Home</span>
        </button>
        <ul class="dropdown-menu absolute hidden text-gray-700 pt-1" style={{ zIndex: "1000" }}>
          <li class=""><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/organizers">Committees</a></li>
        </ul>
      </div>
    </NavLink>

    <NavLink href="/program">
      <div class="dropdown inline-block ">
        <button class="font-semibold py-2 rounded inline-flex items-center">
          <span class="mr-1">Program Agenda</span>
        </button>
        <ul class="dropdown-menu absolute hidden text-gray-700 pt-1" style={{ zIndex: "1000" }}>
          <li class=""><a class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/callforpapers">Call For Papers</a></li>
          <li class=""><a class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/dates">Important Dates</a></li>
          <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/submissions">Submission</a></li>
          <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/instruction-for-authors">Instructions for Authors</a></li>
          {/* <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/accepted-papers">Accepted Papers</a></li> */}
        </ul>
      </div>
    </NavLink>
    <NavLink href="/cyberwarrior">CyberWarrior</NavLink>
    <NavLink target="_blank" href="https://svcc2020.svcsi.org/">
      <div class="dropdown inline-block ">
        <button class="font-semibold py-2 rounded inline-flex items-center">
          <span class="mr-1">Past Conferences</span>
        </button>
        <ul class="dropdown-menu absolute hidden text-gray-700 pt-1" style={{ zIndex: "1000" }}>
          <li class=""><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" target="_blank" href="https://svcc2021.svcsi.org/">SVCC 2021</a></li>
          <li class=""><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" target="_blank" href="https://svcc2020.svcsi.org/">SVCC 2020</a></li>
        </ul>
      </div>
    </NavLink>
    {/* <NavLink href="/coming-soon">Speakers</NavLink> */}
    {/* <NavLink href="/coming-soon">
      <div class="dropdown inline-block ">
        <button class="font-semibold py-2 rounded inline-flex items-center">
          <span class="mr-1">Agenda</span>
        </button> */}
    {/* <ul class="dropdown-menu absolute hidden text-gray-700 pt-1" style={{ zIndex: "10000" }}>
          <li class=""><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/agenda">Overview</a></li>
            <li class=""><a class=" bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/program">Program</a></li>
            <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/distinguishedResearch">A Distinguished Research Forum</a></li>
            <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/tutorials">Tutorials</a></li>
            <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/accepted-papers">Accepted Papers</a></li>
          </ul> */}
    {/* </div>
    </NavLink> */}
    {/* <NavLink href="/coming-soon">Hackathon</NavLink> */}

    {/* <NavLink href="/sponsors">Sponsors</NavLink> */}
    {/* <PrimaryLink css={false && tw`rounded-full`} href="/registration">Register</PrimaryLink> */}
    {/* <NavLink target="_blank" href="https://svcc2020.svcsi.org/">
      <div class="dropdown inline-block ">
        <button class="font-semibold py-2 rounded inline-flex items-center">
          <span class="mr-1">Past Conferences</span>
        </button>
        <ul class="dropdown-menu absolute hidden text-gray-700 pt-1" style={{ zIndex: "1000" }}>
          <li class=""><a class="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" target="_blank" href="https://svcc2020.svcsi.org/">SVCC 2020</a></li> */}
    {/* <li class=""><a class="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/dates">Important Dates</a></li>
            <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/submissions">Submission</a></li>
            <li class=""><a class="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap" href="/instruction-for-authors">Instructions for Authors</a></li>
             */}
    {/* </ul>
      </div>
    </NavLink> */}
  </NavLinks>
];
