import React, { useState } from "react";
import tw from "twin.macro";
import {
    useParams
} from "react-router-dom";
import styled from "styled-components";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Document, Page } from "react-pdf";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import Header from './../components/headers/light';
import Footer from './../components/footers/MiniCenteredFooter.js';
const HeadingRow = tw.div`flex`;
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({

}) => {
    const papers = [
        {
            "title": "Poster: Survey of Consensus Mechanisms in Permissionless Public Blockchains",
            "author": "Ashwin Ramaswamy, Younghee Park",
            "link": "https://drive.google.com/file/d/10G5HJTJE5HWP4jZpIyZQptnJVJCmUTqA/view?usp=sharing",
            "abstract": `Blockchain consensus is an essential process for the validation of transactions and the propagation of blocks to the distributed ledger. However, to maintain scalability and decentralization, secure consensus protocols in blockchain must involve a randomness component, stake in a physical resource, and require proof of action from the nodes involved in the consensus. With numerous possible applications for the blockchain, there are an unlimited number of ways consensus could be designed. As a result, every year there are a myriad of new proposed blockchains with novel or modified consensus mechanisms, each with its own advantages and disadvantages. This paper provides insight of the current art of works in consensus mechanisms in permissionless blockchain and proposes a promising research direction to solve current research challenges.`,
            "file": "/files/SVCC_2021_paper_11.pdf"
        },
        {
            "title": "Poster: 5G ID Privacy Boosting and Randomization Requirements",
            "author": "Arijet Sarker, Manohar Raavi, SangHyun Byun, Jinoh Kim, Jong-Hyun Kim, and Sang-Yoon Chang",
            "link": "https://drive.google.com/file/d/18HsSKzpXxE8hRNVSttdR7PglFnHg_zZf/view?usp=sharing",
            "abstract": `Dynamic, temporary, pseudonym-based identifiers/ID’s are
used to protect the user’s location and transaction privacy in mobile and
telecommunication networking. In this work, we study and identify the
requirements for securing the dynamic temporary ID’s, including two
previously overlooked requirements. These two requirements are especially important for resource-constrained and low-latency applications
and for the compatibility with the 5G networking for wider deployment.
Our paper will inform the design and implementations of future solutions for boosting the privacy (i.e., more difficult to track location and
transactions/data) for securing the privacy of mobile users.`,
            "file": "/files/SVCC_2021_paper_12.pdf"
        },
        {
            "title": "Poster: Phishing Detection for Chabots: Do traditional machine learning approaches rely on the wrong features",
            "author": "Suriya Palanikumar and Jorjeta Jetcheva",
            "link": "https://drive.google.com/file/d/1xRCqVq3dlhhifBDkKHkZjN9UiRh_Z8eT/view?usp=sharing",
            "abstract": `Traditional approaches to phishing detection rely heavily on the
availability of features that are not available within the 1s response time
required by interactive chatbot applications. In this paper, we explore the
impact of removing the reliance on such features on a range of machine
learning models, and find that performance does in fact degrade by 12-13%
when tested on a public benchmark dataset which includes 11,430 URLs (half
of them malicious) and 89 features.`,
            "file": "/files/SVCC_2021_paper_13.pdf"
        },
        {
            "title": "Poster: Study of Feature Engineering for Machine Learning-Based Intrusion Detection Systems",
            "author": "Kicho Yu",
            "link": "https://drive.google.com/file/d/1dQ8hG7B4inFKNDAcO_tl3k3iFwafnZfl/view?usp=sharing",
            "abstract": `Machine learning for network intrusion detection aims to derive associative patterns from available sample data and utilizes statistical techniques to find consistent and useful malicious patterns in network
data. Feature engineering in machine learning is one of the most important tasks for high-performance network intrusion detection systems. In
this project, I investigate the impact of a feature set and I turn parameters by utilizing 4 popular machine learning algorithms: Logistic
Regression, Decision Tree, K-Nearest Neighbors, and Random Forest. I
evaluate each algorithm with 3 public datasets, such as NSL-KDD 99,
Kyoto 2000, and UNSW-NB15.`,
            "file": "/files/SVCC_2021_paper_14.pdf"
        },
        {
            "title": "Paper: Automated Flag Detection And Participant Performance Evaluation For Pwnable CTF",
            "author": "Manikant Singh, Rohit Negi, and Sandeep K Shukla",
            "link": "https://drive.google.com/file/d/1-nbw_dQo-VthxbhTk5T8H2PUQXzonX-F/view?usp=sharing",
            "abstract": `The demand for cyber security awareness, education, evaluation of learning levels of students etc., has increased in the past few
years. In order to meet this rising demand, several cyber security learn-
ing and training platforms have been developed. Capture the flag (CTF)
platforms and cyber ranges have become primary tools that facilitate
education, training and recruitment of cyber security personnel. These
tools evaluate and rank the participants on the basis of challenges solved
by them. A discrete evaluation mechanism focusing only on flags solved,
fails to ensure that the effort and knowledge demonstrated by the participants while solving the challenge, are factored into the scoring system.
Most of these tools do not even distinguish between participants actually solving the flags vs. those who might copy a captured flag without
actually working on the problem. Further, in flag only scoring systems,
participants feel discouraged as they fail to score without finding the flags
– despite putting in enormous time and effort. In this paper, we present our novel approach to quantify participant’s learning, efforts, and any unethical practices. We award partial scores by automatically capturing
their behavior while solving the CTF problems. We also provide an accurate ranking system with automated solved challenge detection which replaces the need for manual flag submission. In our system, participants get hybrid scores based on their efforts, and organizations get a better and an effective evaluation tool.`,
            "file": "/files/SVCC_2021_paper_15.pdf"
        },
        {
            "title": "Paper: Crowdfunding Non-Fungible Tokens on the Blockchain",
            "author": "Sean Basu, Kimaya Basu, and Thomas H. Austin",
            "link": "https://drive.google.com/file/d/1Ns28Hk5ynJfa-NpfSqvArge2Iuo_a8XM/view?usp=sharing",
            "abstract": `Non-fungible tokens (NFTs) have been used as a way of rewarding content creators. Artists publish their works on the blockchain

as NFTs, which they can then sell. The buyer of an NFT then holds
ownership of a unique digital asset, which can be resold in much the
same way that real-world art collectors might trade paintings.
However, while a deal of effort has been spent on selling works of art on
the blockchain, very little attention has been paid to using the blockchain
as a means of fundraising to help finance the artist’s work in the first
place. Additionally, while blockchains like Ethereum are ideal for smaller
works of art, additional support is needed when the artwork is larger than
is feasible to store on the blockchain.
In this paper, we propose a fundraising mechanism that will help artists
to gain financial support for their initiatives, and where the backers can
receive a share of the profits in exchange for their support. We discuss our
prototype implementation using the SpartanGold framework. We then
discuss how this system could be expanded to support large NFTs with
the 0Chain blockchain, and describe how we could provide support for
ongoing storage of these NFTs.`,
            "file": "/files/SVCC_2021_paper_16.pdf"
        },
        {
            "title": "Paper: Differential Privacy in Privacy-Preserving Big Data and Learning: Challenge and Opportunity",
            "author": "Honglu Jiang, Yifeng Gao, S M Sarwar, Luis GarzaPerez, and Mahmudul Robin",
            "link": "https://drive.google.com/file/d/1bA5hdmFo73YpnzHlktOlsjBIyQG7NqHt/view?usp=sharing",
            "abstract": `Differential privacy (DP) has become the de facto standard of privacy preservation due to its strong protection and sound mathematical foundation, which is widely adopted in different applications such as
big data analysis, graph data process, machine learning, deep learning, and federated learning. Although DP has become an active and influential area, it is not the best remedy for all privacy problems in different
scenarios. Moreover, there are also some misunderstanding, misuse, and great challenges of DP in specific applications. In this paper, we point out a series of limits and open challenges of corresponding research areas. Besides, we offer potentially new insights and avenues on combining differential privacy with other effective dimension reduction techniques and secure multiparty computing to clearly define various privacy models.`,
            "file": "/files/SVCC_2021_paper_17.pdf"
        },
        {
            "title": "Paper: Encryption scheme based on the generalized Suzuki 2-groups and homomorpic encryption",
            "author": "Gennady Khalimov, Yevgen Kotukh, Sang-Yoon Chang, Yaroslav Balytskyi, Maksym Kolisnyk, and Svitlana Khalimova",
            "link": "https://drive.google.com/file/d/1Z11Fa9sYBpRJH1NvQpCGPDLh9MJly7PR/view?usp=sharing",
            "abstract": `This article describes a new implementation of
MST-based encryption for generalized Suzuki 2-groups. The
well-known MST cryptosystem based on Suzuki groups is
built on a logarithmic signature at the center of the group,
resulting in a large array of logarithmic signatures. An encryption scheme based on multiparameter non-
commutative groups is proposed. The multiparameter generalized 2 - Suzuki group was chosen as one of the group
constructions. In this case, a logarithmic signature is
established for the entire group. The main difference from the
known one is the use of homomorphic encryption to construct
coverings of logarithmic signatures for all group parameters.
This design allows encryption to be created that is optimized
for implementation costs, which are determined by the size of
the logarithmic signature and secrecy, which is dependent on
the size of the keys and the final field of the group
representation. In this case, the secrecy of the cryptosystem
is ensured at the level of a brute-force attack.`,
            "file": "/files/SVCC_2021_paper_18.pdf"
        },
        {
            "title": "Paper: Fake malware generation using HMM and GAN",
            "author": "Harshit Trehan and Fabio Di Troia",
            "link": "https://drive.google.com/file/d/1Q_eOv8tBqUFfkanhxFV4tcdPKnUFQ6Eb/view?usp=sharing",
            "abstract": `In the past decade, the number of malware attacks have
grown considerably and, more importantly, evolved. Many researchers
have successfully integrated state-of-the-art machine learning techniques
to combat this ever present and rising threat to information security.
However, the lack of enough data to appropriately train these machine learning models is one big challenge that is still present. Generative modelling has proven to be very efficient at generating image-like synthesized data that can match the actual data distribution. In this paper, we aim
to generate malware samples as opcode sequences and attempt to differentiate them from the real ones with the goal to build fake malware
data that can be used to effectively train the machine learning models.
We use and compare different Generative Adversarial Networks (GAN)
algorithms and Hidden Markov Models (HMM) to generate such fake
samples obtaining promising results.`,
            "file": "/files/SVCC_2021_paper_19.pdf"
        },
        {
            "title": "Paper: Security Threats in Cloud Rooted from Machine Learning- based Resource Provisioning Systems",
            "author": "Hosein Mohammadi Makrani, Hossein Sayadi, Najmeh Nazari, and Houman Homayoun",
            "link": "https://drive.google.com/file/d/19dt2S1k6NMsNqlOGTqIl_O6RAw0_4Dz8/view?usp=sharing",
            "abstract": `Resources provisioning on the cloud is problematic due to
heterogeneous resources and diverse applications. The complexity of such
tasks can be reduced with the aid of Machine Learning. Researchers have found, however, that machine learning poses new threats such as adversarial attacks. Based on our investigation, we found that adversarial ML can target resource provisioning systems (RPS) to perform distributed
attacks. Our work proposes a fake trace generator (FTG), which can
be wrapped around an adversary kernel to avoid detection by the RPS
and to enable the adversary to get co-located with the victim’s virtual machine.`,
            "file": "/files/SVCC_2021_paper_20.pdf"
        },
        {
            "title": "Paper: Toward CMOS based secure communication with low power chaos implementation",
            "author": "Ava Hedayatipour, Ravi Monani, Hosein Sayyadi, Amin Rezaei, and Mehrdad Aliasgari",
            "link": "https://drive.google.com/file/d/1cU-M_AMTSCi-KdQyvSiLM4ZFPa5dUuHs/view?usp=sharing",
            "abstract": `Chaos is a deterministic phenomenon that emerges under certain conditions in a nonlinear dynamic system when the trajectories of the state, variables become periodic and highly sensitive to the initial state. Chaotic systems are flexible, and it has been shown that communication is possible using parametric feedback control. Chaos synchronization is the basis of using chaos in communication. Chaos synchronization refers to the characteristic that the trajectories of two identical chaotic systems, each with its own unique initial condition, converge over time. In this paper, data extraction is performed on different chaotic equations implemented as circuits. Lorenz is the base system implemented in this paper, followed by Modified Lorenz, Chua’s, L ̈u’s, and R ̈ossler systems. More recent systems like Sprott D system are also included in the data extractions. The system implementations are robust and are alternative to the software chaos and architectures which can further reduce the power and the area. These systems are also alternatives for quantum era computing when modes of synchronous and asynchronous ciphering fail. The data extracted organize this different mode of chaos implementation based on the ease of their fabrication in integrated circuits. Performance metrics including power consumption, area, design load, noise and robustness to process and temperature variant, are extracted for each system to demonstrate a figure of merit. The figure of merit showcases chaos equations fitting to be implemented as a transmitter/receiver with a mode of chaotic ciphering in communication.`,
            "file": "/files/SVCC_2021_paper_21.pdf"
        },
        {
            "title": "Paper: Towards Building Intrusion Detection Systems for Multivariate Time- Series Data",
            "author": "ChangMin Seong, YoungRok Song, Jiwung Hyun, Yun-Gyung Cheong",
            "link": "https://drive.google.com/file/d/1hTjMhHH7Jzv8PJREwBJltq-IH6R_GUwN/view?usp=sharing",
            "abstract": `Recent network intrusion detection systems have employed machine
learning and deep learning algorithms to defend against dynamically evolving
network attacks. While most previous studies have focused on detecting attacks
that can be determined based on a single time instant, few studies have paid
attention to subsequence outliers, which require inspecting consecutive points in
time for detection. To address this issue, this paper applies a time-series anomaly
detection method in an unsupervised learning manner. To this end, we converted
the UNSW-NB15 dataset into the time-series data. We carried out a preliminary evaluation to test the performance of the anomaly detection on the created time-series network dataset as well as on a time-series dataset obtained from sensors. We analyze and discuss the results.`,
            "file": "/files/SVCC_2021_paper_22.pdf"
        },
        {
            "title": "Paper: Towards Securing Availability in 5G: Analyzing the Injection Attack Impact on Core Network",
            "author": "Manohar Raavi, Simeon Wuthier, Arijet Sarker, Jinoh Kim, Jong-Hyun Kim, and Sang-Yoon Chang",
            "link": "https://drive.google.com/file/d/1VjFveqnQBuBoMFM_sLAFneXKEH_u8pqm/view?usp=sharing",
            "abstract": `5G technology for mobile networking involves control communications to set up the radio channels and the authentication and
security credentials. The control communications preceding the authentication and subscription verification remain vulnerable against the communication injection threats. We study the injection threats on control
communications in 5G New Radio standard in 3GPP. From our 5G client-
based implementation and experimentation against real-world network-
ing, we analyze and measure the threat impact against the 5G service
provider infrastructure of the core network. To secure 5G networking,
our paper discusses about future research directions for increasing the
understanding of such vulnerability/threat and for building greater security and availability for 5G networking against such wireless injection
threats.`,
            "file": "/files/SVCC_2021_paper_23.pdf"
        }
    ];
    let { paper } = useParams();
    let displayPaper = [];
    console.log(paper);
    console.log(paper.split("-").join(" "));
    if (paper) {
        displayPaper = papers.filter(f => f.title.split(" ").join("-") === paper);
        if (displayPaper.length > 0) displayPaper = displayPaper[0];
    }
    console.log(displayPaper);
    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    // const currAgenda = agenda[activeDurationIndex];
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    return (
        <AnimationRevealPage>
            <Header />
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    <div className="py-6">
                        <h1 style={{ "color": "#6415FF", fontSize: "2rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            {displayPaper.title}
                        </h1>
                    </div>
                    <div className="py-1">
                        <h1 style={{ fontSize: "1.4rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            {displayPaper.author}
                        </h1>
                    </div>
                    <div className="pt-6">
                        <h1 style={{ fontSize: "1rem", fontWeight: "500", textAlign: "justify", marginTop: "0px", marginLeft: "0px" }}>
                            {displayPaper.abstract}
                        </h1>
                    </div>
                    <div className="py-8">
                        {/* <embed src={displayPaper.link} width="800px" height="2100px" /> */}
                        {/* <iframe src={displayPaper.link}  style={{"width":"600px", "height":"500px"}} frameborder="0"></iframe> */}
                        <object width="100%" height="800" data={displayPaper.file} type="application/pdf" >   </object>

                    </div>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
