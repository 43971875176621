import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import { Link, Redirect } from 'react-router-dom';
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
        const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-lg xl:text-lg`;
        const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
        const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;
        return (
            <div>
                <AnimationRevealPage>
                    <div className="mx-2">
                        <div class="min-h-screen bg-gray-100">
                            <div class="min-h-screen flex justify-center">
                                <div class="w-2/3 mx-auto">
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div>
                                                    <div class="font-bold text-center text-gray-600" style={{ textAlign: "center" }}>
                                                        Release of Data Mining tutorial links
                                                    </div>
                                                    <div class="text-center" style={{ textAlign: "center" }}>
                                                        <Link to="/datathon/tutorials" target="_blank" className="underline text-purple-600 hover:text-blue-800 visited:text-purple-900">Available Here</Link>
                                                    </div>

                                                </div>
                                                {/* <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">11:59 P.M. (PT)</div> */}
                                            </div>
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute flex flex-col justify-center h-32 w-32 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin">
                                                    <div>15</div>
                                                    <div>October</div>
                                                    {/* <div>9 to 1 P.M. (PT)</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute  text-gray-600 flex flex-col justify-center h-32 w-32 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin">
                                                    <div>22</div>
                                                    <div>October</div>
                                                    {/* <div>11:59 P.M. (PT)</div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-2/5 px-2 py-10 ">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div class="text-gray-600 ">
                                                    <div class="font-bold text-center" style={{ textAlign: "center" }}>
                                                        Release of Datathon introduction and instructions video
                                                    </div>
                                                    <div class="text-center" style={{ textAlign: "center" }}>
                                                        <Link to={{ pathname: "https://youtu.be/wdLwnbFItlU" }} target="_blank" className="underline text-purple-600 hover:text-blue-800 visited:text-purple-900">Available Here</Link>
                                                    </div>
                                                </div>
                                                {/* <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">11:59 P.M. (PT)</div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div class="text-gray-600" style={{ textAlign: "center" }}>
                                                    <div class="font-bold">
                                                        Registration Deadline <span className="font-normal">(<span className="text-red-600 font-bold">Firm</span>)</span>
                                                        {/* (<span className="text-red-600">Firm</span>) */}
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">11:59 P.M. (PT)</div>
                                            </div>

                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute font-bold flex flex-col justify-center h-32 w-32 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin" style={{ color: "red" }}>
                                                    <div>24</div>
                                                    <div>October</div>
                                                    {/* <div>11:59 P.M. (PT)</div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-2/5 px-2 py-10 ">
                                        </div>
                                    </div>
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute flex flex-col justify-center h-32 w-32 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin">
                                                    <div>25</div>
                                                    <div>October</div>
                                                    {/* <div>9 to 1 P.M. (PT)</div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-2/5 px-2 py-10 ">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div class="text-gray-600">
                                                    <div class="font-bold" style={{ textAlign: "center" }}>
                                                        Datathon Kick-Off <span className="font-normal">(students strongly encouraged to participate)</span>
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">10:00 A.M. - 10:30 A.M. (PT)</div>
                                            </div>
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5 mt-4">
                                                <div class="text-gray-600">
                                                    <div class="font-bold" style={{ textAlign: "center" }}>
                                                        Virtual office hours (Zoom)
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">10:30 A.M. - Noon (PT)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5" >
                                                <div class="text-gray-600 ">
                                                    <div class="font-bold text-center" style={{ textAlign: "center" }}>
                                                        Virtual office hours (Zoom)
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">10:00 A.M. - Noon (PT)</div>
                                            </div>
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute flex flex-col justify-center h-32 w-32 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin">
                                                    <div>2</div>
                                                    <div>November</div>
                                                    {/* <div>9 to 1 P.M. (PT)</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10">
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute flex flex-col justify-center h-32 w-32 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin font-bold" style={{ color: "red" }}>
                                                    <div>21</div>
                                                    <div>November</div>
                                                    {/* <div>9 A.M. to 12 P.M. (PT)</div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="w-2/5 px-2 py-10 ">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5" >
                                                <div class="text-gray-600 ">
                                                    <div class="font-bold text-center" style={{ textAlign: "center" }}>
                                                        Final Submission Deadline
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">11:59 P.M. (PT)</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row w-full">
                                        <div class="w-2/5 px-2 py-10 ">
                                            <div class="flex flex-col w-full rounded-lg shadow bg-white px-4 py-5">
                                                <div class="text-gray-600">
                                                    <div class="font-bold" style={{ textAlign: "center" }}>
                                                        Award Announcement
                                                    </div>
                                                </div>
                                                <div style={{ fontSize: "1rem", textAlign: "center" }} className="text-xl">
                                                    {/* 9 A.M. to 12 P.M. (PT) at SVCC 2021 */}
                                                    TBD
                                                    </div>
                                            </div>
                                        </div>
                                        <div class="w-1/5  flex justify-center">
                                            <div class="relative flex h-full w-1 bg-green-300 items-center justify-center">
                                                <div class="absolute flex flex-col justify-center h-32 w-32 rounded-full border-2 border-green-300 leading-none text-center z-10 bg-white font-thin">
                                                    {/* <div>21</div>
                                                    <div>November</div> */}
                                                    <div>3</div>
                                                    <div>December</div>
                                                    {/* <div>11:59 P.M. (PT)</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </AnimationRevealPage>
            </div>
        );
    }
}

export default Main;