import React from "react";
import tw from "twin.macro";

import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import MainFeature from "components/features/TwoColWithButton.js";
import heroScreenshotImageSrc from "images/under_construction.png";

export default () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;   
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature
        subheading={<Subheading>Stay Tuned.</Subheading>}
        heading = {"Page Coming Soon"}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={false}
        imageDecoratorBlob={true}
        description={""}
        primaryButtonText = "Back to Home"
        primaryButtonUrl = "/"
      />
     <Footer />
    </AnimationRevealPage>
  );
};
