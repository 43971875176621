import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings.js";
import FatimaRiveria from "images/keynoteSpeakers/FatimaRiveria.jpg";
import NathalieBaracaldo from "images/keynoteSpeakers/NathalieBaracaldo.jpg";
import DomenicForte from "images/keynoteSpeakers/DomenicForte.jpeg";
import Footer from "components/footers/MiniCenteredFooter.js";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const HeadingMain = tw.h1`font-black text-primary-500 text-2xl md:text-5xl leading-snug max-w-3xl`;
const Heading = tw(HeadingTitle)`mt-4 font-black text-left text-3xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const TalkDescription = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-16 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const SmallCard = styled.div(props => [
    tw`mt-24 md:flex-col justify-center items-center w-9/12`,
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const BigImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-1000 md:h-1000 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const PresentationTitle = tw.h4`text-xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
    const cards = [
        {
            imageSrc:
                FatimaRiveria,
            subtitle: "Futurizing Detection Beyond Keeping Pace",
            title: "Fátima Rivera",
            description: "Fátima Rivera is a Security Engineering Manager at Google working on Detection for the past 10 years. She’s led multiple detection efforts spanning Alphabet. Before joining Google, Fatima earned dual Masters in Computer Science and Information Security at The Johns Hopkins University.",
            talkTitle: "Futurizing Detection Beyond Keeping Pace",
            abstract: "The threat landscape evolves much more rapidly than the systems and methods used to detect threats. To keep pace with attackers, companies must design systems that support detection at scale paired with methodologies that evolve as quickly as the systems and networks themselves. Machine Learning presents plenty of opportunities for Detection & Response teams to tackle both but the traditional SOC approach has to undergo rapid change before those gains can be realized.",
            isOnlySpeaker: true,
        },
        {
            imageSrc: NathalieBaracaldo,
            subtitle: "We can’t protect what we don’t understand: Demystifying AI Security & Privacy",
            title: "Nathalie Baracaldo",
            description:
                "Nathalie Baracaldo leads the AI Security and Privacy Solutions team and is a Research Staff Member at IBM’s Almaden Research Center in San Jose, CA. Nathalie is passionate about delivering machine learning solutions that are highly accurate, withstand adversarial attacks and protect data privacy. Nathalie has led her team to the design of the IBM Federated Learning framework, which is now part of the Watson Machine Learning product. In 2020, Nathalie received the IBM Master Inventor distinction for her contributions to IBM Intellectual Property and innovation. Nathalie also received the 2021 Corporate Technical Recognition, one of the highest recognitions provided to IBMers for breakthrough technical achievements that have led to notable market and industry success for IBM. This recognition was awarded for Nathalie's contribution to the Trusted AI Initiative. Nathalie has received multiple best paper awards and published in top-tier conferences and journals. Nathalie’s research interests include security and privacy, distributed systems and machine learning. Nathalie received her Ph.D. degree from the University of Pittsburgh in 2016.",
            talkTitle: "We can’t protect what we don’t understand: Demystifying AI Security & Privacy",
            abstract: "The adoption of AI systems in clinical diagnosis, fraud detection, recommendation systems, spam and other daily life products is becoming ubiquitous and tangibly affects people around the globe. At the same time, this wide availability has raised questions about the trustworthiness, security, and privacy implications of using these systems. One can only wonder, what are those concerns and how serious are they? Should we be worried about them? In this talk, I will address these questions by demystifying existing threats to the machine learning pipeline that include poisoning and privacy leakage attacks. I will also present state-of-the-art mitigation techniques and their level of effectiveness. Because machine learning has started to move towards a more decentralized and private sphere (partially due to legislation and privacy considerations), I will cover both traditional centralized machine learning where all the training data has been transmitted to a central place, and its more private counterpart, federated learning, where multiple participants collaboratively train machine learning models without exchanging their training data.  At the end, I will highlight open challenges in the area.",
            isOnlySpeaker: true,
        },
        {
            imageSrc:
                DomenicForte,
            subtitle: "Microelectronics Supply Chain Revisited: Lessons Learned and Promising New Directions",
            title: "Domenic Forte",
            description: "Domenic Forte is an Associate Professor and the Steven A. Yatauro Faculty Fellow with the Electrical and Computer Engineering Department at University of Florida.He is also Associate Director for the Florida Institute for National Security(FINS).His research covers the domain of hardware security from transistors to printed circuit boards where he has over 200 publications.Dr.Forte is a senior member of the IEEE, a member of the ACM, and serves or has served on the technical program committees of leading events such as USENIX Security, NDSS, HOST, ASHES, DAC, ICCAD, ITC, ISTFA, and BTAS.Dr.Forte is a recipient of the Presidential Early Career Award for Scientists and Engineers(PECASE), the NSF CAREER Award, and the ARO Young Investigator Award.His research has also been recognized with best paper awards and nominations from IJCB, ISTFA, HOST, DAC, and AHS.For teaching and advising, he has received the Herbert Wertheim College of Engineering Doctoral Dissertation Advisor/ Mentoring Award, the Excellence in Teaching Award from UF’s ECE Graduate Student Organization, and the George Corcoran Outstanding Teaching Award from University of Maryland.",
            talkTitle: "Microelectronics Supply Chain Revisited: Lessons Learned and Promising New Directions",
            abstract: "From the Big Hack to the Colonial Pipeline breach, the last few years have shown government, industry, and society how relentless, sophisticated, and impactful attacks on critical infrastructure can be.As a result, supply chain security and resilience have garnered significant interest and even bipartisan support from lawmakers in the US.Examples include executive orders from the current and previous presidential administrations and the much- needed CHIPS act.However, as two decades of research in hardware security have shown, securing the microelectronics supply chain is easier said than done.This talk reviews the lessons learned by the community and introduces promising new directions inspired by advances in other domains, promote “zero trust” in integrated circuit(IC) design and fabrication, and bolster the physical security of critical systems.",
            isOnlySpeaker: true,
        },

        // {
        //   imageSrc:
        //     "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
        //   subtitle: "Exclusive",
        //   title: "Lollapalooza, Manhattan",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        //   url: "https://timerse.com"
        // }
    ];

    return (
        <AnimationRevealPage>
            <Header />
            <Container>
                <SingleColumn>

                    <HeadingMain>Keynote Speakers</HeadingMain>

                    <Content>
                        {cards.map((card, i) => (
                            <div>
                                <Card key={i} reversed={i % 2 === 1}>
                                    {card.isOnlySpeaker && <Image style={{ height: "480px", width: "390px" }} imageSrc={card.imageSrc} />}
                                    {!card.isOnlySpeaker && <BigImage style={{ height: "900px", width: "390px" }} imageSrc={card.imageSrc} />}
                                    <Details>
                                        {/* <Subtitle>{card.subtitle}</Subtitle> */}
                                        <Title>{card.title}</Title>
                                        {card.isOnlySpeaker && <Description>{card.description}</Description>}
                                        {!card.isOnlySpeaker &&
                                            <div>
                                                <Description>
                                                    {card.description1}
                                                </Description>
                                                <Description>
                                                    {card.description2}
                                                </Description>
                                            </div>
                                        }
                                    </Details>
                                </Card>
                                <br />
                                {card.talkTitle && <Heading><HighlightedText>Talk Title </HighlightedText> {card.talkTitle} </Heading>}
                                {card.abstract && <TalkDescription><b>Abstract : </b> <i> {card.abstract} </i></TalkDescription>}
                                {/* <SmallCard>
                                <PresentationTitle>{card.title}</PresentationTitle>
                                <Description>{card.description}</Description>
                            </SmallCard> */}
                            </div>
                        ))}
                    </Content>
                </SingleColumn>
                <SvgDotPattern1 />
                <SvgDotPattern2 />
                <SvgDotPattern3 />
                <SvgDotPattern4 />
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
