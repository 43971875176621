import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Timeline from './timeline';
import Commitee from './commitee';
import { PrimaryButton } from "../../components/misc/Buttons.js";
import Footer from "../../components/footers/MiniCenteredFooter.js";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${'' /* ${tw`mt-2 leading-loose`} */}
    ${tw`mt-2 leading-normal`}
  }
  h1 {
    ${tw`text-3xl font-bold`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  a {
      ${tw`underline text-blue-600 hover:text-blue-800 visited:text-purple-600`}
  }
  ul {
    ${tw`list-disc list-outside`}
    li {
      ${tw`ml-12`}
      p {
        ${'' /* ${tw`mt-0 inline leading-normal`} */}
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;


export default ({ headingText = "Terms And Condition" }) => {
    return (
        <AnimationRevealPage>
            <Header />
            <Container >
                <ContentWithPaddingXl>
                    <div className="">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.2rem", fontWeight: "700", textAlign: "center", marginTop: "-10px", marginLeft: "2px" }}>
                            Data mining tutorial links
                        </h1>
                    </div>
                    {/* <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow> */}
                    <br />
                    <br />
                    <Text>
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/watch?v=inN8seMm7UI" target="_blank">Google Colab Video</a>
                            </li>
                            <li>
                                <a href="https://scikit-learn.org/stable/tutorial/basic/tutorial.html" target="_blank">Scikit-Learn Intro to ML</a>
                            </li>
                            <li>
                                <a href="https://matplotlib.org/stable/tutorials/introductory/usage.html#sphx-glr-tutorials-introductory-usage-py" target="_blank">Matplotlib Usage Guide</a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=qErBw-R2Ybk" target="_blank">Matplotlib Video Tutorial</a>
                            </li>
                            <li>
                                <a href="https://www.learndatasci.com/tutorials/python-pandas-tutorial-complete-introduction-for-beginners/" target="_blank">Pandas Intro Guide</a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=-o3AxdVcUtQ" target="_blank">EDA Video Tutorial</a>
                            </li>
                            <li>
                                <a href="https://www.geeksforgeeks.org/python-seaborn-tutorial/" target="_blank">Seaborn Tutorial</a>
                            </li>
                        </ul>
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
