import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as WebsiteIcon } from "images/website-icon.svg";
import FatimaRiveria from "images/keynoteSpeakers/FatimaRiveria.jpg";
import NathalieBaracaldo from "images/keynoteSpeakers/NathalieBaracaldo.jpg";
import DomenicForte from "images/keynoteSpeakers/DomenicForte.jpeg";
import KimDoowon from "images/specialTalkSpeakers/KimDoowon.jpeg";
import EnginArslan from "images/specialTalkSpeakers/EnginArslan.jpeg";
import EricChanTin from "images/specialTalkSpeakers/EricChanTin.jpeg";
import YousungKang from "images/specialTalkSpeakers/YousungKang.jpg";

const keyNoteSpeakers = [{
    imageSrc: FatimaRiveria,
    position: "Security Engineering Manager at Google",
    name: "Fátima Rivera",
    profileLink: "fatima-rivera",
    description: "Fatima is a Security Engineering Manager at Google working on Detection for the past 10 years. She’s led multiple detection efforts spanning Alphabet. Before joining Google, Fatima earned dual Masters in Computer Science and Information Security at The Johns Hopkins University.",
    links: [
        {
            url: "https://www.linkedin.com/in/fatimarivera",
            icon: LinkedinIcon
        }
    ],
    talks: [{
        title: "Futurizing Detection Beyond Keeping Pace",
        abstract: "The threat landscape evolves much more rapidly than the systems and methods used to detect threats. To keep pace with attackers, companies must design systems that support detection at scale paired with methodologies that evolve as quickly as the systems and networks themselves. Machine Learning presents plenty of opportunities for Detection & Response teams to tackle both but the traditional SOC approach has to undergo rapid change before those gains can be realized."
    }],
    redirectURI: '/speakers'
}, {
    imageSrc: NathalieBaracaldo,
    position: "Manager AI Security and Privacy Solutions, Research Staff Member",
    name: "Nathalie Baracaldo",
    profileLink: "nathalie-baracaldo",
    description: "Nathalie Baracaldo leads the AI Security and Privacy Solutions team and is a Research Staff Member at IBM’s Almaden Research Center in San Jose, CA. Nathalie is passionate about delivering machine learning solutions that are highly accurate, withstand adversarial attacks and protect data privacy. Nathalie has led her team to the design of the IBM Federated Learning framework, which is now part of the Watson Machine Learning product. In 2020, Nathalie received the IBM Master Inventor distinction for her contributions to IBM Intellectual Property and innovation. Nathalie also received the 2021 Corporate Technical Recognition, one of the highest recognitions provided to IBMers for breakthrough technical achievements that have led to notable market and industry success for IBM. This recognition was awarded for Nathalie's contribution to the Trusted AI Initiative. Nathalie has received multiple best paper awards and published in top-tier conferences and journals. Nathalie’s research interests include security and privacy, distributed systems and machine learning. Nathalie received her Ph.D. degree from the University of Pittsburgh in 2016.",
    links: [
        {
            url: "https://researcher.watson.ibm.com/researcher/view.php?person=us-baracald",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "We can’t protect what we don’t understand: Demystifying AI Security & Privacy",
        abstract: "The adoption of AI systems in clinical diagnosis, fraud detection, recommendation systems, spam and other daily life products is becoming ubiquitous and tangibly affects people around the globe. At the same time, this wide availability has raised questions about the trustworthiness, security, and privacy implications of using these systems. One can only wonder, what are those concerns and how serious are they? Should we be worried about them? In this talk, I will address these questions by demystifying existing threats to the machine learning pipeline that include poisoning and privacy leakage attacks. I will also present state-of-the-art mitigation techniques and their level of effectiveness. Because machine learning has started to move towards a more decentralized and private sphere (partially due to legislation and privacy considerations), I will cover both traditional centralized machine learning where all the training data has been transmitted to a central place, and its more private counterpart, federated learning, where multiple participants collaboratively train machine learning models without exchanging their training data.  At the end, I will highlight open challenges in the area."
    }],
    redirectURI: '/speakers'
},
{
    imageSrc: DomenicForte,
    position: "Associate Professor at University of Florida, Gainesville",
    name: "Domenic Forte",
    profileLink: "domenic-forte",
    description: "Domenic Forte is currently an Associate Professor and the Steven A. Yatauro Faculty Fellow in the Electrical and Computer Engineering (ECE) Department at the University of Florida. Previously, he was an Assistant Professor in the Electrical and Computer Engineering (ECE) Department at the University of Connecticut. He received my PhD in Electrical and Computer Engineering from the University of Maryland in 2013. His research interests include Counterfeit Electronics Detection and Avoidance, Hardware Security Primitives, Hardware Trojan Detection and Prevention, Nanoscale Integration Challenges, Reverse Engineering and Anti-reverse Engineering, Computer-Aided Design (CAD) Tools, and, Biometric Authentication.",
    links: [
        {
            url: "https://www.linkedin.com/in/domenic-forte-13233153/",
            icon: LinkedinIcon
        },
        {
            url: "HTTPS://DFORTE.ECE.UFL.EDU/ABOUT/",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "TBD",
        abstract: "TBD"
    }],
    redirectURI: '/speakers'
},
];

const specialTalkSpeakers = [{
    imageSrc: KimDoowon,
    position: "Assistant Professor at University of Tennessee, Knoxville",
    name: "Kim Doowon",
    profileLink: "kim-doowon",
    description: "Doowon Kim is an Assistant Professor in the Department of Electrical Engineering and Computer Science (EECS) at the University of Tennessee, Knoxville (UTK). He joined UTK in Fall 2020 after he received his Ph.D. in Computer Science from the University of Maryland, College Park. He has worked on cyber security, including authentication (Public Key Infrastructures), systems security, malware analysis, Web, etc. His cyber security works have appeared at top-tier security conference venues, including IEEE Security & Privacy (S&P), ACM Conference on Computer and Communications Security (CCS), USENIX Security, and the Web Conf (formerly WWW). He was awarded the NSA Best Scientific Cybersecurity Paper (2017) and the Ann G. Wylie Dissertation Fellowship (2019).",
    links: [
        {
            url: "https://www.linkedin.com/in/doowon-kim/",
            icon: LinkedinIcon
        },
        {
            url: "https://doowon.github.io/",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Understanding of the security threats in the software supply chain ecosystem",
        abstract: "A software supply chain is a complex series of software development processes required to build a software artifact. The processes are logically and tightly chained to each other process including coding, building, packaging/code-signing, and releasing. Such a chain is called a software supply chain. Unfortunately, the complex series of steps have led to an increase in the attack surfaces, and the software supply chain ecosystem has suffered supply chain attacks (e.g., SolarWinds). To this end, we address security problems and challenges in each development process by better understanding the stakeholders of each process with data-driven and human-centered perspectives."
    }],
    redirectURI: '/specialTalkSpeakers'
}, {
    imageSrc: EnginArslan,
    position: "Assistant Professor at University of Nevada, Reno",
    name: "Engin Arslan",
    profileLink: "engin-arslan",
    description: "Dr. Engin Arslan is an Assistant Professor at the Department of Computer Science and Engineering at the University of Nevada, Reno (UNR) since 2017. He received his Ph.D. from University at Buffalo in 2016 and worked at National Science for Supercomputing Applications (NCSA) as a postdoctoral research associate for one year after graduation. His research interests include high-performance computing and networking, computer networks, file systems, edge and cloud computing, storage systems, and applied machine learning. His work in these areas is published at top conferences and journals including IEEE/ACM Supercomputing, USENIX NSDI, IEEE/ACM CCGrid, IEEE Cluster, and IEEE Transactions on Parallel and Distributed Systems (TPDS). He is also the recipient of several prestigious grants including NSF CRII, NSF Campus Cyberinfrastructure (CC*), and NSF CAREER. Dr. Arslan also serves in several committees including the editorial board of IEEE TPDS and the Cyberinfrastructure Committee of UNR.",
    links: [
        {
            url: "https://www.cse.unr.edu/~earslan/",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Flood Control: TCP-SYN Flood Detection using OpenFlow Port Statistics",
        abstract: "As cyber attacks are becoming new normal, it is increasingly important to develop effective solutions to defend networks against attacks. A prominent cyberattack that can compromise networks is TCP-SYN floods, which can exhaust network resources by initiating too many fraudulent TCP connections. Previous efforts to detect SYN Flood attacks mainly rely on statistical methods to process mirrored traffic or flow statistics. Thus, they either incur high overhead (in the case of port mirroring) or lead to low accuracy (in the case of using flow statistics). In this paper, we propose a machine learning (ML)-enabled TCP-SYN flood detection framework using Openflow port statistics. We demonstrate that ML models such as Random Forest classifiers can differentiate normal traffic from SYN flood traffic with up to 98% accuracy. We also introduce a novel threat localization technique that can pinpoint where the attack traffic originates in the network."
    }],
    redirectURI: '/specialTalkSpeakers'
},
{
    imageSrc: EricChanTin,
    position: "Associate Professor at Loyola University Chicago",
    name: "Eric Chan-Tin",
    profileLink: "eric-chan-tin",
    description: "Dr. Eric Chan-Tin is currently an associate professor in the Department of Computer Science, the lead of the Center for Cybersecurity and Privacy, and the PI for the NSA/DHS Center of Academic Excellence in Cyber Defense at Loyola University Chicago. Previously, he was an associate professor in the Computer Science Department at Oklahoma State University. He received his Ph.D. degree from the University of Minnesota and his B.A. from Macalester College. His research areas are in network security, distributed systems, privacy, anonymity, and at the intersection of cybersecurity and social sciences. He has published over 45 peer-reviewed papers, including publications at top conferences and journals such as ACM CCS, NDSS, ACM TISSEC, and IEEE TIFS. In 2020, he was recognized as a Master Researcher in the College of Arts and Sciences.",
    links: [
        {
            url: "https://www.linkedin.com/in/eric-chan-tin/",
            icon: LinkedinIcon
        },
        {
            url: "https://chantin.cs.luc.edu",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Take this Personality Quiz to find your Password Type",
        abstract: "This talk will explore how different personalities create passwords of varying strength. It is well-known that each person has a specific personality. We will show how different people create passwords of varying strength, based on their personality. Moreover, each participant will be nudged with either a matching or mismatching message based on their personality to change their password creation behavior. This talk will analyze whether the type of message helps improve cybersecurity behavior. Moreover, this talk will also look at the relationship between phishing and personality traits -- what kind of people are less likely to fall victim to phishing attacks? The results of this research can be used to create personalized cybersecurity training which is more effective than current generic cybersecurity training."
    }],
    redirectURI: '/specialTalkSpeakers'
},
{
    imageSrc: YousungKang,
    position: "Director at ETRI",
    name: "Yousung Kang",
    profileLink: "yousung-kang",
    description: "Dr. Yousung Kang joined Electronics and Telecommunications Research Institute (ETRI) in 1999, and he is now the Director leading Cryptographic Engineering Research Section. He was a visiting researcher at Queen‚Äôs University Belfast, Northern Ireland, UK, from 2011 to 2012. He obtained his BS and MS in Electronics Engineering from Chonnam National University, Gwangju, Korea in 1997 and in 1999, respectively. He received his Ph.D in Electrical and Electronic Engineering from KAIST, Daejeon, Korea in 2015. His research interests include quantum security, key-hiding technology and applications, side channel analysis, and the areas of cryptographic engineering.",
    links: [
        {
            url: "https://www.linkedin.com/in/yousung-kang-839aa030/",
            icon: LinkedinIcon
        },
    ],
    talks: [{
        title: "How can we evaluate the quantum security of cryptographic algorithms?",
        abstract: "The security of cryptographic algorithm is generally determined by how much memory and operations are required to attack the algorithm. The quantum security for cryptographic algorithm can be defined in the similar way. The quantum security is determined by how many quantum resources, such as the number of qubits and quantum operations, are required to attack. In this talk, the <Q|Crypton>, ETRI's quantum security evaluation platform for cryptographic algorithms, is introduced. This talk will be helpful in understanding the difference between the theory and practice of quantum security."
    }],
    redirectURI: '/specialTalkSpeakers'
},
];

const otherSpeakers = [{
    imageSrc: "/img/malek.jpeg",
    position: "Technology executive and the Americas Security R&D lead for Accenture.",
    name: "Malek Ben Salem",
    profileLink: "malek-ben-salem",
    description: "Dr. Malek BEN SALEM is a technology executive and the Americas Security R&amp;D lead forAccenture. She works on building innovative AI and security technologies helping organizationsreduce their risk and to proactively adhere to digital ethics principles in order to earn clients’trust—going beyond a compliance-driven security approach. Her research covers AI and IoTsecurity, Trustworthy AI, data protection, security analytics, and cloud and mobile security. Dr. BenSalem holds a PhD and a MSc in Computer Science from Columbia University, New York, a Dipl.-Ing. in Electrical Engineering from the Technical University of Hanover, GERMANY, and a certificatein Technology Strategy from the MIT Sloan School of Business.",
    links: [
        {
            url: "https://www.linkedin.com/in/malek-ben-salem/",
            icon: LinkedinIcon
        }
    ]

},
{
    imageSrc: "/img/judith-furlong.jpg",
    position: "Distinguished Engineer focused on security technology and strategy in the Dell Technologies Chief Technology Office",
    name: "Judy Furlong",
    profileLink: "judith-furlong",
    description: "Judy Furlong is a Distinguished Engineer focused on security technology and strategy in the Dell Technologies Chief Technology Office. She is passionate about defining and enabling a consistent anddifferentiating security capabilities across the Dell Technologies portfolio. Judy co-chairs the OASIS KeyManagement and Interoperability Protocol (KMIP) Technical Committee and is a contributor to securityactivities within OASIS, CSA, SAFECode and SNIA. Judy holds a MS and a BS in Electrical Engineeringfrom the Massachusetts Institute of Technology (MIT).",
    links: [
        {
            url: "https://www.linkedin.com/in/judithfurlong/",
            icon: LinkedinIcon
        }
    ]
},
// {
//     imageSrc: "/img/sara.jpg",
//     position: "Assistant Professor, Department of Electrical & Computer Engineering at the Santa Clara University (SCU)",
//     name: "Sara Tehranipoor",
//     profileLink: "sara-tehranipoor",
//     description : "Sara Tehranipoor is am an Assistant Professor of the Department of Electrical andComputer Engineering at the Santa Clara University (SCU), joined Fall 2019. Previously, Iwas an Assistant Professor of Engineering at the San Francisco State University for twoyears from 2017 to 2019, and awarded my Ph.D. in Electrical and Computer Engineeringfrom the University of Connecticut, in July 2017. My research interests include HardwareSecurity, Internet-of-Things (IoT) Security, and Embedded Systems. I received the “BestTechnical Paper Award” in the 30th International Conference on VLSI Design (VLSID) &amp;16th International Conference on Embedded Systems. I am currently serving as anassociate editor for IEEE Consumer Electronics Magazine.",
//     links : [
//         {
//            url : "http://tehranipoor.com/",
//            icon : WebsiteIcon
//         }
//     ]
// },
{
    imageSrc: "/img/jorjeta-jetcheva.jpg",
    position: " Assistant Professor, Computer Engineering Department at San José State University",
    name: "Jorjeta Jetcheva",
    profileLink: "jorjeta-jetcheva",
    description: "Jorjeta Jetcheva is an Assistant Professor in the Computer Engineering Department atSan José State University. Her current areas of research focus are Artificial Intelligence-based Personal Assistants, Natural Language Processing, and Knowledge Management.Prior to San Jose State, she was the global R&amp;D lead for the Virtual Assistant portfolioof Accenture Operations, where she was responsible for leading a global teamconceptualizing, building and deploying Virtual Assistants, AI-Powered KnowledgePlatforms, and Agent Assist solutions. Prior to Accenture, she was a Research Scientistand Manager at Fujitsu Laboratories of America (FLA), a Principal Engineer in Itron&#39;sSmart Grid Architecture and Standards team, and the Systems Architect of Bay Areastartup Firetide, covering a broad range of technology areas including Virtual andRobotic Assistants, Smart Grid analytics and applications, wireless and mobilenetworking, and cybersecurity. Professor Jetcheva received a Ph.D. degree in Computer Science from Carnegie MellonUniversity, and a B.A. degree with a double major in Computer Science (summa cumlaude) and Mathematics from Mount Holyoke College.",
    links: [
        {
            url: "https://www.linkedin.com/in/jorjetajetcheva/",
            icon: LinkedinIcon
        }
    ]
}];


const careerPanelists = [{
    imageSrc: "/img/melike-erol-kantarci.jpg",
    position: "Canada Research Chair in AI-Enabled Next-Generation Wireless Networks Associate Professor, School of Electrical Engineering and Computer Science , University of Ottawa",
    name: "Melike Erol-Kantarci",
    profileLink: "melike-erol-kantarci",
    description: "Melike Erol-Kantarci is Tier 2 Canada Research Chair in AI-enabled Next-Generation WirelessNetworks and associate professor at the School of Electrical Engineering and Computer Science atthe University of Ottawa. She is the founding director of the Networked Systems andCommunications Research (NETCORE) laboratory. She has over 140 peer-reviewed publicationswhich have been cited over 5000 times and she has an h-index of 36. Recently, she was selectedfor the 2019 list of “N2Women: Stars in Computer Networking and Communications” along with 7other distinguished scientists. She has received several best paper awards including the IEEECommunication Society Best Tutorial Paper Award in 2017. Dr. Erol-Kantarci is the co-editor of threebooks on smart grids, smart cities and intelligent transportation. She has delivered 40+ plenary talks,tutorials and seminars around the globe. She is the Specialty Chief Editor for the Smart GridCommunications Section of the journal “Frontiers in Communications and Networking”. She is on theeditorial board of the IEEE Transactions on Cognitive Communications and Networking, IEEEInternet of Things Journal, IEEE Communications Letters, IEEE Vehicular Technology Magazine andIEEE Access. She has acted as the general chair and technical program chair for many internationalconferences and workshops; most recently TPC Chair for IEEE CAMAD 2020, track chair for IEEEGlobecom 2020, IEEE SmartGridComm 2020 and IEEE CCNC 2021. Her main research interestsare AI-enabled wireless networks, 5G and 6G wireless communications, smart grid, electric vehicles,Internet of things and cybersecurity. She is a senior member of the IEEE.",
    links: [
        {
            url: "http://www.site.uottawa.ca/~merolka2/",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Security in the Era of Hyper Connected World",
        abstract: "The world is transforming into a hyper connected place where a simple smartwatch can connect to the most sophisticated device in critical infrastructure in a few hops. Likewise, humans, cars, factories are becoming a part of a connected ecosystem where security becomes more important than ever. Building hard fortresses of security is becoming more challenging too. In this talk, we will focus on secure access being an integrated part of the hyper connectivity paradigm. We will present how AI can help with seamless authentication."
    }]
},
{
    imageSrc: "https://images.squarespace-cdn.com/content/v1/5e67cd61d3d039551d9349d2/1599845712860-NJ1Y4WEBUUC8X3DASQMI/ke17ZwdGBToddI8pDm48kNhLVqbMSQe1LATz2gm9H5ZZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwuIcb__zrC4pJLHjKaxKSQJ74OELJkLV05aabR_pTgiF7QgOruKFXA9EXPXFMlomY/MGuel_Pic.jpg",
    position: " Distinguished Engineer & Data Security and Privacy Strategist at Cisco",
    name: "Michele D. Guel",
    profileLink: "michele-d-guel",
    description: "Michele D. Guel has been an avid speaker, influencer and evangelist in the cybersecurity industry for 31years. She joined Cisco in March 1996 as the founding member of Cisco’s internal security team. Duringher 24+ years at Cisco, she has worked in all facets of data, information, network security, IoT operationalsecurity excellence and has established many “firsts” at Cisco. In her current role in the Office of the CTOfor the Security Business Group, she is focused on data security and privacy strategies for Cisco productsand offerings. In 2010, Michele was promoted to Distinguished Engineer (DE), one of 9 female DEsacross Cisco today. In 2014 she co-founded Cisco’s Women in Cybersecurity Community which focuseson developing the next generation of women cybersecurity leaders. In 2016 she received the prestigiousAnita Borg’s 2016 Women of Vision Technology Leadership Award.",
    links: [
        {
            url: "https://www.linkedin.com/in/michele-d-guel-6992993/",
            icon: LinkedinIcon
        }
    ],
    talks: [{
        title: "A Peek into 2040 – The Technology, the Threat and Needed Product Capabilities",
        abstract: "In this fast-paced Keynote, Michele will give her thoughts on common technology in the year 2040 and what may be probable security threats given what we know today.  What we have today will certainly change and how we approach securing our products, our services and our infrastructures much change.   With a view of technology and probably security threats in 2040 we’ll discuss 5 key traits on that are a must for security products (any products really) moving forward."
    }]
}];

const distinguishedResearchForum = [{
    imageSrc: "/img/zhiqiang-lin.jpg",
    position: "Associate Professor of Computer Science and Engineering (CSE) at Ohio State University",
    name: "Zhiqiang Lin",
    profileLink: "zhiqiang-lin",
    description: "",
    links: [
        {
            url: "https://web.cse.ohio-state.edu/~lin.3021/#toc2",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Uncovering BLuetooth Link Layer Vulnerabilities via Automated Binary Analysis of Bare-metal Firmware",
        abstract: "Being a near-range wireless communication protocol, Bluetooth, particularly its Low Energy version, is ubiquitous in the Internet-of-Things (IoT) devices today because of its extremely lower energy consumption. However, the security and privacy implication of these Bluetooth devices is not well understood. In this talk, Dr. Lin will present how to use binary analysis to identify the security vulnerabilities in real-world Bluetooth devices. In particular, he will talk about FirmXRay (https://github.com/OSUSecLab/FirmXRay), an open-source tool developed by his group to automatically analyze the bare-metal firmware of Bluetooth devices to identify the link-layer vulnerabilities such as insecure pairing and unauthorized read/write. The details of how to develop this tool and the identified vulnerabilities will be shared in this talk."
    }]
},
{
    imageSrc: "/img/attila.jpg",
    position: "Assistant Professor, The Department of Computer Science and Engineering, University of South Florida",
    name: "Attila Yavuz",
    profileLink: "attila-yavuz",
    description: "",
    links: [
        {
            url: "https://www.csee.usf.edu/~attilaayavuz/",
            icon: WebsiteIcon
        }
    ],
    talks: [{
        title: "Energy Efficient Digital Signatures for Embedded Medical Devices",
        abstract: "Embedded medical devices collect security-critical healthcare data whose authentication and integrity must be guaranteed. Although symmetric cryptography is efficient, it lacks public verifiability and non-repudiation that are necessary for medical auditing and dispute resolution. Digital signatures provide these features, but existing alternatives are extremely energy costly for battery-limited embedded medical devices. In this talk, we present light-weight digital signatures that are specifically designed for highly resource-limited devices. We will describe Signer Efficient Multiple-time Elliptic Curve Signature (SEMECS) that achieves optimal signature and private key sizes for an EC-based signature without requiring any EC operation (e.g., EC scalar multiplication or addition) at the signer. Our experiments on an 8-bit AVR microprocessor confirm up to 19x less battery-consumption for SEMECS as compared to its fastest counterpart SchnorrQ. We will also discuss Energy-aware digital signatures for Embedded Medical devices (ESEM) that offer polynomially unbounded signing capability with a distributed verification. With their optimal signing efficiency and provable security, SEMECS and ESEM are ideal digital signatures for embedded medical devices."
    }]
},
{
    imageSrc: "/img/Luis-Bathen.png",
    position: "Research Staff, IBM Research",
    name: "Luis Bathen",
    profileLink: "Luis-Bathen",
    description: "",
    links: [
        {
            url: "https://www.linkedin.com/in/bathen/",
            icon: LinkedinIcon
        }
    ],
    talks: [{
        title: "Decentralized Identity at the Edge",
        abstract: "Decentralized identity has become a hot topic since the emergence of blockchain technology. Community interest has gone beyond simple user-based key management onto standardization of decentralized identifiers. Similarly, as connectivity continues to evolve and the imminent deployments of 5G networks, edge computing has become more and more a reality. This talk explores use-cases of decentralized identity at the edge, including the use in autonomous vehicles and biometrics."
    }]
}
];


const tutorials = [
    {
        category: "By Trend Micro",
        date: "December 19, 2020 , 9:30 a.m. – 12 p.m. (PST)",
        title: "Tutorial Session 1: Cybersecurity in Digital Transformation by Trend Micro team (Session Chair: Mitchel Chang)",
        zoom: "https://trendmicro.zoom.us/j/92053131593?pwd=djgxVHkxT09GZk5EbU9wQ0N0aWFlUT09",
        topics: [
            {
                title: "Cybersecurity in Digital Transformation (Industry 4.0)",
                speakers: "Mitchel Chang, SVP, CSR and Education, Trend Micro",
                links: [
                    {
                        url: "https://www.linkedin.com/in/mitchelchang",
                        icon: LinkedinIcon
                    }
                ],
                videoLink: "https://www.youtube.com/watch?v=4M5SQX-r-TA&feature=youtu.be"
            },
            {
                title: "IoT Threats and Solutions",
                speakers: "Jerry Liao, Senior Director of R&D, Trend Micro",
                videoLink: "https://www.youtube.com/watch?v=4M5SQX-r-TA&t=478&feature=youtu.be"
            },
            {
                title: "Industrial IoT, IT and OT Convergence",
                speakers: "Richard Ku, SVP, Commercial IoT Market Development, Trend Micro",
                videoLink: "https://www.youtube.com/watch?v=4M5SQX-r-TA&feature=youtu.be"
            },
            {
                title: "Enterprise Threat Management",
                speakers: "Steven Allison, Senior Solution Consultant, Trend Micro",
                videoLink: "https://www.youtube.com/watch?t=5439&v=4M5SQX-r-TA&feature=youtu.be"
            }
        ],
    },
    {
        category: "By experts in Blockchain and Security",
        date: "December 19, 2020 , 12:30 p.m. – 4 p.m. (PST)",
        title: "Tutorial Session 2: Blockchain and Security",
        topics: [
            {
                title: "Evolution of Consensus Mechanisms on the Permissionless Blockchain",
                speakers: "Thomas H. Austin, Associate Professor, Computer Science Department in San Jose State University.",
                links: [
                    {
                        url: "https://www.svcsi.online/thomas-bio",
                        icon: WebsiteIcon
                    }
                ],
                summary: "In Bitcoin, miners achieve consensus on transactions through a proof-of-work protocol. We will review how Bitcoin's consensus mechanism has evolved and review challenges it faces: the rise of ASIC miners, mining pools, selfish-mining attacks,and increased centralization. We will review proposed solutions for defending against these attacks, and also discuss how other blockchain consensus mechanisms attempt to address these problems. We will review proof-of-stake protocols like Peercoin and Tendermint and discuss their advantages and limitations compared to Bitcoin."
            },
            {
                title: "Blockchain Networking Security",
                speakers: "Sang-Yoon Chang, Assistant Professor at the Computer Science Department in University of Colorado Colorado Springs.",
                links: [
                    {
                        url: "https://academics.uccs.edu/~schang2",
                        icon: WebsiteIcon
                    }
                ],
                summary: "Blockchain uses the distributed peer-to-peer (P2P) networking. The networking is critical because it delivers information, such as new blocksand transactions, and enables the distributed consensus protocol and the rest of the blockchain operations. In this talk, we will review the unique security challenges in blockchain networking, the vulnerabilities and threats, the threat impacts to the blockchain application, and the potential countermeasures."
            },
            {
                title: "Ethereum Smart Contracts Development",
                speakers: "Dan Nolan, Co-Founder at ChainShot Inc.",
                links: [
                    {
                        url: "https://www.linkedin.com/in/dannolan99/",
                        icon: LinkedinIcon
                    }
                ],
                summary: "Developing smart contracts is a fantastic way to learn about the Ethereum ecosystem. Let's get hands-on in this interactive coding session and build our own smart contracts while integrating them into an existing Ethereum protocol on the application layer. Then we'll learn how to set up these smart contracts in a local environment for further development."
            }
        ],
    }
];

const fundRaising = [{
    imageSrc: "img/front_large_extended.jpg",
    name: "Hanes Beefy T-shirt",
    link: "https://www.customink.com/fundraising/svcc2020?side=front&type=1&zoom=false",
    description: "Available in white, ash  & heather blue",
},
{
    imageSrc: "img/front_large_extended_black.jpg",
    name: "Hanes Beefy T-shirt",
    link: "https://www.customink.com/fundraising/3svcc2020?side=front&type=2&zoom=false",
    description: "Available in black & light Steel",
}];

export { keyNoteSpeakers, otherSpeakers, careerPanelists, distinguishedResearchForum, tutorials, fundRaising, specialTalkSpeakers };
