import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";
import Commitee from './commitee';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-10 md:py-12 py-20 md:pb-4`;
const LeftColumn = tw.div`relative lg:w-8/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto lg:pb-24`;
const Heading = tw.h1`font-black text-primary-500 text-xl md:text-3xl leading-snug max-w-3xl mb-2`;
const HeadingMain = tw.h1`font-black text-primary-500 text-2xl md:text-5xl leading-snug max-w-3xl mb-2`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;
const SubParagraph = tw.p`my-5 lg:my-8 text-sm text-gray-600 mx-auto lg:mx-0`;
const HeadingRow = tw.div`flex`;
const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;
const Text = styled.div`
  ${tw`text-base text-gray-800 mb-8`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

const specialSessionsChairMembers = [
  {
    imageSrc: require("../../images/specialsessions/nima.jpeg"),
    position: "Assistant Professor, San Jose State University",
    name: "Nima Karimian",
    links: [
      {
        url: "https://www.linkedin.com/in/nima-karimian-2966ba53/",
        icon: LinkedinIcon
      }
    ]
  },
  {
    imageSrc: require("../../images/specialsessions/hossein.jpeg"),
    position: "Assistant Professor, California State University, Long Beach",
    name: "Hossein Sayadi",
    links: [
      {
        url: "https://www.linkedin.com/in/hosseinsayadi/",
        icon: LinkedinIcon
      }
    ]
  },
];

export default ({
  heading = "CALL FOR SPECIAL SESSIONS",
  watchVideoYoutubeUrl = "https://www.youtube.com/embed/_GuOjXYl5ew",
  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = true,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Container className="mx-4">
        <TwoColumn>
          <LeftColumn>
            <HeadingMain>{heading}</HeadingMain>
            <SubParagraph style={{ textAlign: "justify", textJustify: "interWord" }}>
              Silicon Valley Cybersecurity Conference (SVCC 2021) is soliciting proposals for special sessions from both
              academia and industry. In conjunction with the regular SVCC conference program, the aim of special
              sessions is to provide specialized forums for the discussion of complementary, novel, or timely perspectives
              in cybersecurity that are of general interest to the audience of SVCC. A special session could consist of a set
              of individual presentations or a panel. The special sessions should aim at providing a complementary
              experience with respect to the regular sessions. Emerging topics such as systems security,
              hardware/architecture security, IoT and network security, and application of artificial intelligence and
              machine learning for all aspects of cybersecurity are particularly encouraged, but all interesting proposals
              that are aligned with the core context of SVCC will be considered.
                </SubParagraph>
            {/* <Paragraph>{description}</Paragraph> */}
            {/* <Actions>
              <PrimaryButton as="a" target="_blank" href="https://easychair.org/conferences/?conf=svcc2020">{primaryButtonText}</PrimaryButton>
            </Actions> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
              />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
          </div>
        </StyledModal>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Special Session Chairs</Heading>
          </HeadingRow>
          <Commitee subheading="" description="" cards={specialSessionsChairMembers} />
          <br />
          <HeadingRow>
            <Heading>Proposal Submission Instructions (August 31st, 2021)</Heading>
          </HeadingRow>

          <Text className="text-justify font-bold">
            Please submit your proposal, 3-4 pages long, consisting of:
            <p />
            <ul>
              <li>
                Abstract and Title for Special Session
              </li>
              <li>
                Rationale of the need for the special session at SVCC by highlighting the novelty of the topic and
                how it is different from the subjects covered by the regular sessions.
              </li>
              <li>
                Session organizers short bio, contact, and affiliations
              </li>
              <li>
                Must include a list of suggested participants with biographical data
              </li>
              <li>
                List of (3-4) contributing presentations (including title, presenter, contact information of the
                corresponding presenter, and an abstract for each presentation)
              </li>
            </ul>
          </Text>
          <HeadingRow>
            <Heading>Important Dates</Heading>
          </HeadingRow>
          <Text>
            <ul>
              <li>Special Session proposal due: <b>August 31, 2021 at Midnight (Your Timezone)</b></li>
              <li>Acceptance Notification: <b>September 30, 2021 at Midnight (Your Timezone)</b></li>
              <li>Camera-Ready Paper Due: <b>November 15, 2021 at Midnight (Your Timezone)</b></li>
            </ul>
          </Text>
          <Text className="text-justify">
            <p></p>
            <b>All special session proposals should be submitted through the SCVV website or sent to Special
            Session Chairs, at</b> <b><u><a href="mailto:nima.karimian@sjsu.edu">nima.karimian@sjsu.edu</a>, <a href="mailto:hossein.sayadi@csulb.edu">hossein.sayadi@csulb.edu</a></u></b>. Proposals will be evaluated
            based on the needs of the topic, the qualifications of the organizers, and the presenters of the session.
            Each presenter of the contributing papers of an accepted special session can submit an invited paper up to
            15 - 16 pages on the CCIS single column format, covering the main aspects of their presentation. The
            invited papers will be included in the conference proceedings.
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </>
  );
};
