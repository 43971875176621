import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import Header from './../components/headers/light';
import Footer from './../components/footers/MiniCenteredFooter.js';
const HeadingRow = tw.div`flex`;
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
    subheading = "Agenda",
    heading = "Agenda",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plans = null,
    primaryButtonText = "Buy Now",
    planDurations = [
        {
            text: "Overview",
            switcherText: "Overview",
            twoColumns: false
        },
        {
            text: "Dec 17",
            switcherText: "Dec 17",
            twoColumns: true
        },
        {
            text: "Dec 18",
            switcherText: "Dec 18",
            twoColumns: false
        },
        {
            text: "December 19",
            switcherText: "Dec 19",
            twoColumns: true
        }
    ]
}) => {
    const papers = [
        {
            "title": "Poster: Survey of Consensus Mechanisms in Permissionless Public Blockchains",
            "author": "Ashwin Ramaswamy, Younghee Park",
            "link": "https://drive.google.com/file/d/1qhxCYSmqg67jnzsDdJQkiZRjjt3G9mxD/view?usp=sharing"
        },
        {
            "title": "Poster: 5G ID Privacy Boosting and Randomization Requirements",
            "author": "Arijet Sarker, Manohar Raavi, SangHyun Byun, Jinoh Kim, Jong-Hyun Kim, and Sang-Yoon Chang",
            "link": "https://drive.google.com/file/d/18HsSKzpXxE8hRNVSttdR7PglFnHg_zZf/view?usp=sharing"
        },
        {
            "title": "Poster: Phishing Detection for Chabots: Do traditional machine learning approaches rely on the wrong features?",
            "author": "Suriya Palanikumar and Jorjeta Jetcheva",
            "link": "https://drive.google.com/file/d/1xRCqVq3dlhhifBDkKHkZjN9UiRh_Z8eT/view?usp=sharing"
        },
        {
            "title": "Poster: Study of Feature Engineering for Machine Learning-Based Intrusion Detection Systems",
            "author": "Kicho Yu",
            "link": "https://drive.google.com/file/d/1dQ8hG7B4inFKNDAcO_tl3k3iFwafnZfl/view?usp=sharing"
        },
        {
            "title": "Paper: Automated Flag Detection And Participant Performance Evaluation For Pwnable CTF",
            "author": "Manikant Singh, Rohit Negi, and Sandeep K Shukla",
            "link": "https://drive.google.com/file/d/1-nbw_dQo-VthxbhTk5T8H2PUQXzonX-F/view?usp=sharing"
        },
        {
            "title": "Paper: Crowdfunding Non-Fungible Tokens on the Blockchain",
            "author": "Sean Basu, Kimaya Basu, and Thomas H. Austin",
            "link": "https://drive.google.com/file/d/1Ns28Hk5ynJfa-NpfSqvArge2Iuo_a8XM/view?usp=sharing"
        },
        {
            "title": "Paper: Differential Privacy in Privacy-Preserving Big Data and Learning: Challenge and Opportunity",
            "author": "Honglu Jiang, Yifeng Gao, S M Sarwar, Luis GarzaPerez, and Mahmudul Robin",
            "link": "https://drive.google.com/file/d/1bA5hdmFo73YpnzHlktOlsjBIyQG7NqHt/view?usp=sharing"
        },
        {
            "title": "Paper: Encryption scheme based on the generalized Suzuki 2-groups and homomorpic encryption",
            "author": "Gennady Khalimov, Yevgen Kotukh, Sang-Yoon Chang, Yaroslav Balytskyi, Maksym Kolisnyk, and Svitlana Khalimova",
            "link": "https://drive.google.com/file/d/1Z11Fa9sYBpRJH1NvQpCGPDLh9MJly7PR/view?usp=sharing"
        },
        {
            "title": "Paper: Fake malware generation using HMM and GAN",
            "author": "Harshit Trehan and Fabio Di Troia",
            "link": "https://drive.google.com/file/d/1Q_eOv8tBqUFfkanhxFV4tcdPKnUFQ6Eb/view?usp=sharing"
        },
        {
            "title": "Paper: Security Threats in Cloud Rooted from Machine Learning- based Resource Provisioning Systems",
            "author": "Hosein Mohammadi Makrani, Hossein Sayadi, Najmeh Nazari, and Houman Homayoun",
            "link": "https://drive.google.com/file/d/19dt2S1k6NMsNqlOGTqIl_O6RAw0_4Dz8/view?usp=sharing"
        },
        {
            "title": "Paper: Toward CMOS based secure communication with low power chaos implementation",
            "author": "Ava Hedayatipour, Ravi Monani, Hosein Sayyadi, Amin Rezaei, and Mehrdad Aliasgari",
            "link": "https://drive.google.com/file/d/1cU-M_AMTSCi-KdQyvSiLM4ZFPa5dUuHs/view?usp=sharing"
        },
        {
            "title": "Paper: Towards Building Intrusion Detection Systems for Multivariate Time- Series Data",
            "author": "ChangMin Seong, YoungRok Song, Jiwung Hyun, Yun-Gyung Cheong",
            "link": "https://drive.google.com/file/d/1hTjMhHH7Jzv8PJREwBJltq-IH6R_GUwN/view?usp=sharing"
        },
        {
            "title": "Paper: Towards Securing Availability in 5G: Analyzing the Injection Attack Impact on Core Network",
            "author": "Manohar Raavi, Simeon Wuthier, Arijet Sarker, Jinoh Kim, Jong-Hyun Kim, and Sang-Yoon Chang",
            "link": "https://drive.google.com/file/d/1VjFveqnQBuBoMFM_sLAFneXKEH_u8pqm/view?usp=sharing"
        }
    ];
    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    // const currAgenda = agenda[activeDurationIndex];
    return (
        <AnimationRevealPage>
            <Header />
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    <div className="py-8">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            Accepted Papers
                        </h1>
                    </div>
                    <table class="table-auto self-center w-full">
                        <thead>
                            <tr className="h-24">
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm"></th>
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm">Paper Title</th>
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm">Author(s)</th>
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm">View PDF</th>
                                <th class="px-4 py-2 text-center bg-blue-200 border-2 font-bold text-sm">View Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {papers.map((paper, idx) => {
                                return (
                                    <tr>
                                        <td class="border-2 px-4 py-2  bg-gray-200 text-sm" >#{idx + 1}</td>
                                        <td class="border-2 px-4 py-2  bg-gray-200 text-sm" >{paper.title}</td>
                                        <td class="border-2 px-4 py-2  bg-yellow-100 text-sm">{paper.author}</td>
                                        <td class="border-2 px-4 py-2 bg-pink-100 text-sm text-center"><a href={paper.link} target="_blank">View</a></td>
                                        <td class="border-2 px-4 py-2 bg-pink-100 text-sm text-center"><a href={"/accepted-papers/" + paper.title.split(" ").join("-")} >View Details</a></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
