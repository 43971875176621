import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import Agenda from "../Main";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import radhika from "../../../images/keynoteSpeakers/RadhikaRastogi.png";
import cindy from "../../../images/keynoteSpeakers/Cindy.png";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-primary-500 text-gray-100`}
`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl h-24 border-b`;
const LeftColumn = tw.div`relative lg:w-2/12 lg:mt-0 flex flex-col lg:items-center lg:text-center font-bold text-xs justify-center  h-full  border-l px-4 border-gray-500 bg-red-100`;
const LeftColumn2 = tw.div`relative lg:w-2/12 lg:mt-0 flex flex-col lg:items-center lg:text-center font-bold text-xs justify-center  h-full  border-l px-4 border-gray-500 bg-gray-100`;

const DateColumnTop = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-sm font-bold justify-center h-full border-l border-r border-gray-500`;
const DateColumnTop2 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-sm font-bold justify-center h-full border-l  border-gray-500`;
const Day3 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-xs font-bold justify-center h-full border-r border-l border-gray-500 bg-indigo-100`;
const Day2 = tw.div`relative flex flex-col lg:flex-row text-center lg:items-center lg:w-4/12 text-xs font-bold justify-center h-full  border-l border-gray-500 bg-gray-200 `;

const Day2RightColumn = tw.div`relative  lg:w-6/12 lg:mt-0 flex flex-col justify-center font-bold text-xs border-r h-full `;
const RightColumn = tw.div`relative lg:w-6/12 lg:mt-0 flex flex-col justify-center font-bold text-xs border-r h-full`;
const TopLeftColumn = tw.div`relative lg:w-2/12 lg:mt-0 flex flex-col lg:items-center text-center  font-bold text-sm justify-center border-l  h-full border-gray-500`;

const SecondColumn = tw.div`relative lg:w-6/12 flex-shrink-0 text-center lg:text-left font-bold`;
const ThirdColumn = tw.div`relative mt-12 lg:w-6/12 lg:mt-0 flex flex-col justify-center `;
const TwoColumnDual = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-8/12  `;
const DateColumn = tw.div`relative flex flex-col lg:flex-row md:items-center lg:w-4/12 justify-center h-full`;

export default ({
    subheading = "Agenda",
    heading = "Agenda",
    description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    plans = null,
    primaryButtonText = "Buy Now",
    planDurations = [
        {
            text: "Overview",
            switcherText: "Overview",
            twoColumns: false
        },
        {
            text: "Dec 17",
            switcherText: "Dec 17",
            twoColumns: true
        },
        {
            text: "Dec 18",
            switcherText: "Dec 18",
            twoColumns: false
        },
        {
            text: "December 19",
            switcherText: "Dec 19",
            twoColumns: true
        }
    ]
}) => {
    const agenda = [
        {
            "time": "9:00 am to 09:50 am",
            "description": [
                { "title": "Opening Remark", "raffle": 0 },
                // { "title": "-", "raffle": 0 },
                // { "title": "-", "raffle": 0 },
                { "title": "Opening Remark for Hackathon (SJSU Dean)", "raffle": 0 },
                { "title": "-", "raffle": 0 }
            ]
        },
        {
            "time": "10:10 am to 10:40 am",
            "description": [
                { "title": 'Keynote Speaker (Emin Gun Sirer at Cornell University)', "raffle": 1 },
                { "title": "Keynote Speaker (Bhunia Swarup, University of Florida)", "raffle": 1 },
                { "title": "-", "raffle": 0 },
                { "title": "Keynote Speaker (Qiong Zhang, FUJITSU NETWORK COMMUNICATIONS)", "raffle": 1 },
                { "title": "-", "raffle": 0 }
            ]
        },
        {
            "time": "10:40 am to 11:10 am",
            "description": [
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "-", "raffle": 0 },
                { "title": "Tutorial by Trend Micro", "raffle": 1 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
        {
            "time": "11:10 am to 11:40 pm",
            "description": [
                { "title": "Lunch", "raffle": 0 },
                { "title": "Lunch", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "-", "raffle": 0 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
        {
            "time": "11:40 pm to 01:00 pm",
            "description": [
                { "title": "Keynote Speaker (Chris Romeo, Cisco)", "raffle": 1 },
                { "title": "Keynote Speaker (Nina Taft, Google)", "raffle": 1 },
                { "title": "-", "raffle": 0 },
                { "title": "Blockchain Tutorial", "raffle": 1 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
        {
            "time": "01:00 pm to 05:00 pm",
            "description": [
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "Research Presentation", "raffle": 1 },
                { "title": "UNiSEC", "raffle": 0 },
                { "title": "Blockchain Tutorial", "raffle": 0 },
                { "title": "Hackathon", "raffle": 0 }
            ]
        },
    ];
    const schedule = [
        {
            "time": "08:50 am to 09:00 am",
            "category": { "title": "Opening Remark", "raffle": 0 },
            "day1": [
                { "title": "Opening Remarks (Xiaoyan Sun, Gokay Saldamli, Luis Bathen)", "raffle": 0 },
            ],
            "day2": [
                { "title": "-", "raffle": 0 },
            ],
            "day3": [
                { "title": "-", "raffle": 0 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "9:00 am to 09:50 am",
            "category": { "title": "Opening Remark", "raffle": 0 },
            "day1": [
                { "title": "Futurizing Detection Beyond Keeping Pace (Fátima Rivera, Google) (Session Chair: Xiaoyan Sun)", "raffle": 0 },
            ],
            "day2": [
                { "title": "We can’t protect what we don’t understand: Demystifying AI Security & Privacy (Dr. Nathalie Baracaldo, IBM Research) (Session Chair: Younghee Park)", "raffle": 0 },
            ],
            "day3": [
                { "title": "Microelectronics Supply Chain Revisited: Lessons Learned and Promising New Directions (Dr. Domenic Forte, University of Florida) (Session Chair: Nima Karimian)", "raffle": 0 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "10:10 am to 10:40 am",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'A Blockchain-based Retribution Mechanism for Collaborative Intrusion Detection (Wenjun Fan, Shubham Kumar, Sang-Yoon Chang and Younghee Park) (Session Chair: Gokay Saldamli)', "raffle": 1 },
            ],
            "day2": [
                { "title": 'A Blockchain-based Tamper-resistant Logging Framework (Thomas Austin and Fabio Di Troia) (Session Chair: Thomas Austin)', "raffle": 0 }
            ],
            "day3": [
                { "title": "Smart Contracts in the Cloud (Luis Bathen, Divyesh Jadav, IBM Research) (Session Chair: Fabio Di Troia)", "raffle": 0 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "10:40 am to 11:10 am",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Robustness of Image-Based Malware Analysis (Katrina Tran, Fabio Di Troia and Mark Stamp) (Session Chair: Gokay Saldamli)', "raffle": 1 },
            ],
            "day2": [
                { "title": 'Deep IoT Monitoring: Filtering IoT Traffic Using Deep Learning (Gargi Gopalkrishna Prabhugaonkar, Xiaoyan Sun, Xuyu Wang and Jun Dai) (Session Chair: Thomas Austin) ', "raffle": 0 }
            ],
            "day3": [
                { "title": "Twitter bots detection with Benford’s Law and Machine Learning (Sanmesh Bhosale and Fabio Di Troia) (Session Chair: Fabio Di Troia)", "raffle": 0 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "11:10 am to 11:30 am",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Impact of Location Spoofing Attacks on Performance Prediction in Mobile Networks (Nikhil Sai Kanuri, Sang-Yoon Chang, Younghee Park, Jonghyun Kim and Jinoh Kim) (Session Chair: Gokay Saldamli)', "raffle": 1 },
            ],
            "day2": [
                { "title": 'Word Embeddings for Fake Malware Generation (Quang Duy Tran and Fabio Di Troia) (Session Chair: Thomas Austin) ', "raffle": 0 }
            ],
            "day3": [
                { "title": "Award Announcement (Session Chair: Jackie Zang)", "raffle": 0 },
            ],
            "day1rowspan": 2,
            "day2rowspan": 2,
            "day3rowspan": 1,
        },
        {
            "time": "11:30 am to 11:40 am",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": '', "raffle": 1 },
            ],
            "day2": [
                { "title": '', "raffle": 0 }
            ],
            "day3": [
                { "title": 'Closing Remarks (Gokay Saldamli)', "raffle": 0 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "11:40 am to 01:00 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'Lunch Time', "raffle": 1 }
            ],
            "day2": [
                { "title": 'Lunch Time', "raffle": 0 }
            ],
            "day3": [
                { "title": "-", "raffle": 0 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 6,
        },
        {
            "time": "01:00 pm to 01:40 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": 'CyberWarrior (Cybersecurity Competition)', "raffle": 1 }
            ],
            "day2": [
                { "title": 'Flood Control: TCP-SYN Flood Detection using OpenFlow Port Statistics (Dr. Engin Arslan) (Session Chair: Jinoh Kim) ', "raffle": 0 },
            ],
            "day3": [
                { "title": "", "raffle": 0 },
            ],
            "day1rowspan": 5,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "01:40 pm to 02:10 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": '', "raffle": 1 }
            ],
            "day2": [
                { "title": 'Understanding of the security threats in the software supply chain ecosystem (Dr. Doowon Kim) (Session Chair: Jinoh Kim) ', "raffle": 0 },
            ],
            "day3": [
                { "title": '', "raffle": 1 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "02:10 pm to 02:50 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": '', "raffle": 1 }
            ],
            "day2": [
                { "title": 'Take this Personality Quiz to find your Password Type (Dr. Eric Chan-Tin) (Session Chair: Jinoh Kim)', "raffle": 0 },
            ],
            "day3": [
                { "title": '', "raffle": 1 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "02:50 pm to 03:30 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": '', "raffle": 1 }
            ],
            "day2": [
                { "title": 'How can we evaluate the quantum security of cryptographic algorithms? (Dr. Yousung Kang) (Session Chair: Jinoh Kim) ', "raffle": 0 },
            ],
            "day3": [
                { "title": '', "raffle": 1 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
        {
            "time": "03:30 pm to 06:00 pm",
            "category": { "title": "Keynote", "raffle": 1 },
            "day1": [
                { "title": '', "raffle": 1 }
            ],
            "day2": [
                { "title": '-', "raffle": 0 },
            ],
            "day3": [
                { "title": '', "raffle": 1 },
            ],
            "day1rowspan": 1,
            "day2rowspan": 1,
            "day3rowspan": 1,
        },
    ];
    const [activeDurationIndex, setActiveDurationIndex] = useState(0);
    // const currAgenda = agenda[activeDurationIndex];
    return (
        <Container>
            {/* <div style={{ textAlign: "center" }} className="mt-5 text-sm text-gray-600">
                <span style={{ fontSize: "1.2rem", fontWeight: "600" }} className="text-black">Enter To Win !!!</span>
                <br />
                Each session (Marked with Raffle <FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} />) will have a raffle to select a winner who will receive a $20 Amazon gift card.
                <br />Each session will have more than one raffle times and each participant can receive more than one gift card for different sessions.
            </div> */}
            <ContentWithPaddingXl>
                <table class="table-auto self-center w-full">
                    <thead>
                        <tr className="h-24">
                            <th class="px-4 py-2 w-2/12  border-2 bg-blue-200 font-bold text-sm ">All timings in PST</th>
                            {/* <th class="px-4 py-2 w-2/12  border-2 bg-blue-200 font-bold text-sm ">Category</th> */}
                            <th class="px-4 py-2  bg-blue-200 border-2 font-bold text-sm">August 17</th>
                            <th class="px-4 py-2  bg-blue-200 border-2 font-bold text-sm">August 18</th>
                            <th class="px-4 py-2  bg-blue-200 border-2 font-bold text-sm">August 19</th>
                        </tr>
                    </thead>
                    <tbody>
                        {schedule.map((currAgenda, idx) => {
                            return (
                                <>
                                    <tr>
                                        <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={currAgenda.day1.length}>{currAgenda.time}</td>
                                        {/* <td class="border-2 px-4 py-2 font-bold bg-red-100 text-sm" rowSpan={currAgenda.description.length}>{currAgenda.category.title} {currAgenda.category.raffle === 1 && <FontAwesomeIcon icon={faTicketAlt} style={{ color: "orange" }} />} </td> */}
                                        {!currAgenda.day1[0]["title"] == "" && <td class="border-2 px-4 py-2 font-bold bg-yellow-100 text-sm" rowSpan={currAgenda.day1rowspan}>{currAgenda.day1[0]["title"]}</td>}
                                        {!currAgenda.day2[0]["title"] == "" && <td class="border-2 px-4 py-2 font-bold bg-yellow-100 text-sm" rowSpan={currAgenda.day2rowspan}>{currAgenda.day2[0]["title"]}</td>}
                                        {!currAgenda.day3[0]["title"] == "" && <td class="border-2 px-4 py-2 font-bold bg-yellow-100 text-sm" rowSpan={currAgenda.day3rowspan}>{currAgenda.day3[0]["title"]}</td>}
                                        {/* {idx === 0 ?
                                            <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" colSpan={2}>Opening Remark for Hackathon (SJSU Dean) </td> :
                                            idx == 1 ? <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" colSpan={2}>Keynote Speaker (Qiong Zhang, Fujitsu Laboratories of America) </td> :
                                                idx == 2 ? <><td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={3}>Trend Micro<br /> (9:30 a.m. - 12:00 p.m.) </td><td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={7}>Hackathon <br></br>(9:30 a.m.  - 4 p.m.) </td></> :
                                                    idx == 3 ? <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" colSpan={1}>Lunch </td> :
                                                        idx == 4 ? <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={3}>Blockchain Tutorial (12:30 - 16:00) </td> :
                                                            idx == 7 ? <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" colSpan={2}>Award Announcement/Closing Conference (4 -4:30 p.m.)</td> : <td />
                                        } */}
                                    </tr>
                                    {currAgenda.day1.map((d, index) => {
                                        if (index > 0) {
                                            return (
                                                <tr>
                                                    <td class="border-2 bg-yellow-100 px-4 py-2 font-bold text-sm">{d.title}</td>
                                                </tr>
                                            );
                                        }
                                    })}
                                    {currAgenda.day2.map((d, index) => {
                                        if (index > 0) {
                                            return (
                                                <tr>
                                                    <td class="border-2 bg-yellow-100 px-4 py-2 font-bold text-sm">{d.title}</td>
                                                </tr>
                                            );
                                        }
                                    })}
                                    {currAgenda.day3.map((d, index) => {
                                        if (index > 0) {
                                            return (
                                                <tr>
                                                    <td class="border-2 bg-yellow-100 px-4 py-2 font-bold text-sm">{d.title}</td>
                                                </tr>
                                            );
                                        }
                                    })}
                                    {/* {currAgenda.day3.length > 0 && (<tr> {currAgenda.day3} </tr>) && currAgenda.day3.map((d, index) => {
                                        if (index > 0) {
                                            return (
                                                <tr className="">
                                                    <td class="border-2 bg-yellow-100 px-4 py-2 font-bold text-sm">{d.title}</td>
                                                </tr>
                                            );
                                        }
                                    })} */}
                                </>
                            );
                        })}
                        {/* <>
                            <tr>
                                <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={1}>A</td>
                                <td class="border-2 px-4 py-2 font-bold bg-gray-200 text-sm" rowSpan={1}>A</td>
                            </tr>
                        </> */}
                    </tbody>
                </table>
                <br />
            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob2 />
        </Container>
    );
};
