import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Timeline from './timeline';
import Commitee from './commitee';
import { PrimaryButton } from "../../components/misc/Buttons.js";
import Footer from "../../components/footers/MiniCenteredFooter.js";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${'' /* ${tw`mt-2 leading-loose`} */}
    ${tw`mt-2 leading-normal`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-outside`}
    li {
      ${tw`ml-12`}
      p {
        ${'' /* ${tw`mt-0 inline leading-normal`} */}
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const datathonChairMembers = [
    {
        imageSrc: require("../../images/datathon/jorjetaJetcheva.jpg"),
        position: "Assistant Professor, San Jose State University",
        name: "Jorjeta Jetcheva",
        links: [
            {
                url: "https://www.linkedin.com/in/jorjetajetcheva/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/carlosRojas.jpeg"),
        position: "Assistant Professor, San Jose State University",
        name: "Carlos Rojas",
        links: [
            {
                url: "https://www.linkedin.com/in/carlos-rojas-27224520/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/malekBenSalem.jpeg"),
        position: "Director, Accenture",
        name: "Malek Ben Salem",
        links: [
            {
                url: "https://www.linkedin.com/in/malek-ben-salem/",
                icon: LinkedinIcon
            }
        ]
    },
];

const judges = [
    {
        imageSrc: require("../../images/datathon/alvaro.jpeg"),
        position: "Associate Professor, UC Santa Cruz",
        name: "Álvaro Cárdenas",
        links: [
            {
                url: "https://www.linkedin.com/in/%C3%A1lvaro-c%C3%A1rdenas-7817313/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/winnie.jpeg"),
        position: "Director, PwC",
        name: "Dr. Winnie Cheng",
        links: [
            {
                url: "https://www.linkedin.com/in/winniewcheng/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/davidKim.jpeg"),
        position: "Assistant Professor, Georgia State University",
        name: "David Kim",
        links: [
            {
                url: "https://www.linkedin.com/in/donghyun-david-kim-6273b836/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/mahimaSuresh.jpeg"),
        position: "Assistant Professor, San Jose State University",
        name: "Mahima Agumbe Suresh",
        links: [
            {
                url: "https://www.linkedin.com/in/mahimaas/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/nima.jpeg"),
        position: "Assistant Professor, San Jose State University",
        name: "Nima Karimian",
        links: [
            {
                url: "https://www.linkedin.com/in/nima-karimian-2966ba53/",
                icon: LinkedinIcon
            }
        ]
    },
];

export default ({ headingText = "Terms And Condition" }) => {
    return (
        <AnimationRevealPage>
            <Header />
            <Container >
                <ContentWithPaddingXl>
                    <div className="">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "2px" }}>
                            SVCC ’21 UNiSEC Datathon
                        </h1>
                    </div>
                    {/* <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow> */}
                    <Text>

                        <p>This year’s SVCC cybersecurity challenge is a UNiSEC Datathon. The goal of the Datathon is to create an exciting learning
experience at the intersection of cybersecurity and data mining and machine learning.</p>

                        <p>We especially encourage students who are underrepresented in science, engineering, and cybersecurity to participate.  Prior experience with data mining or cybersecurity is not required.  Tutorial videos will be released to introduce students to data mining and how to participate in the Datathon.  Students will also have the option of attending Virtual Office Hours to ask questions.</p>

                        <h1>Who can participate?</h1>
                        <p></p>
                        <ul>
                            <li>
                                Graduate and undergraduate students.
                            </li>
                            <li>
                                Each participating team can have a maximum of 4 members.
                            </li>
                            <li>
                                There are 2 types of teams:
                                <ul style={{ "list-style-type": "circle" }}>
                                    <li>
                                        Undergraduate team (<i><strong>more than 50%</strong></i> of members are undergraduates)
                                    </li>
                                    <li>
                                        Graduate team (<i><strong>50% or more</strong></i> of the members are graduate students)
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Each team needs to include one or more students from groups that are underrepresented in cybersecurity. UNiSEC (UNderrepresented groups in cyberSECurity) includes but is not limited to women, African-American students, Hispanic students, American-Indian students, students with disabilities, low income and first-generation college students.
                            </li>
                            <li>
                                Each participant needs to pay a $10 registration which will be refunded once we verify participation in the
                                competition.
                            </li>
                        </ul>

                        <h1>Timeline</h1>
                        <Timeline />

                        <h1>Evaluation</h1>
                        <p>
                            We will release the dataset for this competition on October 22, 2021 to those who have registered for the Datathon.  The challenge would involve binary classification of packets into malicious and legitimate.
                        </p>
                        <p>
                            Participants will be making submissions on our Datathon Kaggle leaderboard. Submissions for the leaderboard will be
                            evaluated on the Area under the Receiver Operating Characteristic curve (AUC) between the predicted and the observed
                            target. Additionally, the UNiSEC Datathon committee will be reviewing the submitted solutions according to AUC score, and
                            decide on the final ranking based on the originality/sophistication of the solution.
            </p>
                        <h1>UNiSec Datathon Rules</h1>
                        <p></p>
                        <ul>
                            <li>
                                Max number of submissions per team per day is 5.
                                 <ul style={{ "list-style-type": "circle" }}>
                                    <li>Note that Kaggle tracks violations of the rules and will automatically disable accounts of participants who
                                    are using multiple accounts to make submissions in order to be able to make more than 10 submissions
per day.</li>
                                </ul>
                            </li>
                            <li>
                                Information sharing between teams is not allowed.
                            </li>
                            <li>
                                All publicly available information can be utilized.
                            </li>
                            <li>
                                Are people allowed to have faculty advisors? Yes, if you can find your mentor for this competition, you can get
                                some advice from the mentor by October 30, 2021. Also your group needs to inform us about the mentor name
                                and the affiliation by the registration deadline. The mentor can only provide advice instead of directly giving an idea
                                to the group. After October 30, each group must work independently for their submission. Any committee
                                members or any SVCSI members cannot be a mentor for any group due to conflict of interest.
                            </li>
                        </ul>

                        {/* <h1>Prizes</h1>
                        <p></p>
                        <ul>
                            <li>
                                The top selected teams ($1,000 per team)
                            </li>
                            <li>
                                5 Honorable Mention Award ($100 per team)
                            </li>
                        </ul>
                        <p>
                            Depending on the funding, we select around 6 teams as finalists based on the Kaggle leaderboard ranking with their submitted solutions. It will include the top 3 undergraduate teams and the top 3 among the remaining teams (can be graduate or undergraduate). Each of the 6 finalist teams will be awarded $1,000 total (to be split evenly among team members).
                        </p> */}
                        <h1>Top Team Awards</h1>
                        <p>
                            We plan to select up to 4 - 5 teams as finalists based on the Kaggle final leaderboard ranking and the submitted code with a small report for the respective solutions depending on the number of students.  The awards will go to the top 2 - 3 undergraduate teams and the top 2-3 among the remaining teams (can be graduate or undergraduate). Each of the finalist teams will be awarded $1,000 total per team (to be split evenly among all team members).
                        </p>
                        <p>
                            Note that, a single competitor will receive $500 if selected as a winner since the Datathon is a team competition with underrepresented students.
                        </p>
                        <p>
                            The # of awards can be subjected to the number of participant teams and each individual contribution to this competition. Judges will evaluate each team and individual contributions too.
                        </p>
                        <h1>Honorable Mention Awards</h1>
                        <p>
                            We will also grant Honorable Mention Awards of $100 for each participant who shows great contributions to this competition.
                        </p>

                        <h1>UNiSEC Datathon Committee:</h1>
                        <Commitee heading="Datathon Chairs" subheading="" description="" cards={datathonChairMembers} />
                        <Commitee heading="Judges" subheading="" description="" cards={judges} />
                        <p></p>
                        <h1>Registration</h1>
                        <p>Registrations for the UNiSEC datathon are now open. Please check the UNiSEC Datathon Rules section above for additional information.</p>
                        <p className="font-bold text-red-600">If you don't have a group, you can first register it yourself. We can help you to create a group through our pool during the training session.</p>
                        <br />
                        <PrimaryButton buttonRounded="true" as="a" href="https://cs-room.com/event/bp/60dad8cdb69bb43c769d64db" target="_blank">
                            Register now
                        </PrimaryButton>
                        <br />
                        <p />
                        <h2>Registration Fee: $10 per student (Refundable)</h2>
                        <p>
                            <strong>
                                The registration fee will be refunded if you participate in this datathon. Note that the Paypal processing fee (3%) can not be refunded since it is out of our control.
                            </strong>
                        </p>
                        <br />
                        <p>
                            Please feel free to contact us at <a href="mailto:svcc.conf@svcsi.org" className="text-red-600 font-semibold">event.manager@svcsi.org</a> for any questions realted to datathon.
                        </p>
                        <br />
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
