import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../headers/light.js";
import Footer from "../footers/MiniCenteredFooter.js";
import { SectionHeading } from "../misc/Headings";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`
]);
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${'' /* ${tw`mt-2 leading-loose`} */}
    ${tw`mt-2 leading-normal`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-outside`}
    li {
      ${tw`ml-12`}
      p {
        ${'' /* ${tw`mt-0 inline leading-normal`} */}
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "IOT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
        <ContentWithPaddingXl>
          <div className="">
            <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
              Special Talk
                        </h1>
            {/* <b><i>All the deadlines are based in PDT</i></b> */}
          </div>
          <Text style={{ fontSize: "16px", marginTop: "20px" }}>
            <p>
              Silicon Valley Cybersecurity Conference (SVCC 2022) is soliciting a special talk for special sessions
              from both academia and industry. In conjunction with the regular SVCC conference program, the aim of special sessions
              is to provide specialized forums for the discussion of complementary, novel, or timely perspectives in cybersecurity that are of
              general interest to the audience of SVCC. The special sessions should aim at providing a complementary experience with respect to the
              regular sessions. Emerging topics such as systems security, hardware/architecture security, IoT and network security, and application
              of artificial intelligence and machine learning for all aspects of cybersecurity are particularly encouraged. Authors can submit their
             research or industry talk with <b>less than 4 pages single-column</b> in the <a href="https://www.springer.com/gp/computer-science/lncs/conference-proceedings-guidelines" target="_blank" className="hover:underline" style={{ color: "orange" }}>Springer LNCS single-column format</a>.
            </p>

          </Text>

        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};