import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container } from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/headers/light.js";
import Footer from "../components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "../components/misc/Headings";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-2`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 pb-24 lg:pt-8 lg:pb-24`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${'' /* ${tw`mt-2 leading-loose`} */}
    ${tw`mt-2 leading-normal`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-outside`}
    li {
      ${tw`ml-12`}
      p {
        ${'' /* ${tw`mt-0 inline leading-normal`} */}
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "IOT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
    return (
        <AnimationRevealPage>
            <Header />
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    <div>
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "0px" }}>
                            REGISTRATION
                        </h1>
                    </div>

                    {/* <HeadingRow className="text-center items-center">
                        <Heading>Conference Date: <span>December 2 (Thursday), 2020 – December 3 (Friday), 2021.</span></Heading>
                    </HeadingRow> */}

                    <div className="my-4" style={{ textAlign: "center" }}>
                        <PrimaryButton as="a" target="_blank" href="https://cs-room.com/event/bp/617faf490dce1864bac63c61">Register</PrimaryButton>
                    </div>

                    <p className="text-center mt-6" style={{ fontSize: "18px", color: "#6415ff", fontWeight: "600" }}>The registration deadline is November 27, 2021 (Hard Deadline!).</p>

                    <Text>
                        <p>
                            At least one author per paper needs to register the conference as the Open Access
                            author rate ($700) since the Springer publisher will charge the publication fee based on
                            information provided at <a href="https://www.springer.com/gp/computer-science/lncs/open-access-publishing-in-computer-proceedings" target="_blank" className="text-orange-600 font-semibold hover:underline">https://www.springer.com/gp/computer-science/lncs/open-access-publishing-in-computer-proceedings.</a>
                        </p>
                    </Text>
                    <table class="table-auto self-center w-full ">
                        <thead>
                            <tr>
                                <th class="px-4 py-2">Sr. no.</th>
                                <th class="px-4 py-2">Type</th>
                                <th class="px-4 py-2">Rate ($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="border px-4 py-2">1</td>
                                <td class="border px-4 py-2">Research Paper Author</td>
                                <td class="border px-4 py-2">700</td>
                            </tr>
                            <tr>
                                <td class="border px-4 py-2">2</td>
                                <td class="border px-4 py-2">Invited Paper/Poster Author</td>
                                <td class="border px-4 py-2">100</td>
                            </tr>
                            <tr>
                                <td class="border px-4 py-2">3</td>
                                <td class="border px-4 py-2">General Admission</td>
                                <td class="border px-4 py-2">80</td>
                            </tr>

                            <tr>
                                <td class="border px-4 py-2">4</td>
                                <td class="border px-4 py-2">Student Admission</td>
                                <td class="border px-4 py-2">30</td>
                            </tr>
                        </tbody>
                    </table>

                    <Text style={{ fontSize: "16px" }}>
                        <p>* When you register it, please leave your comment about your paper title.</p>
                        <p>
                            * Each paper author needs to register for this conference at either the paper author rate
                            ($700 for OA) or at the invited/poster author rate ($100) depending on the paper type.
                            Anyone else can register for this conference at the general admission rate ($80). The
                            registration fee of students is $30.</p>
                    </Text>
                    <br />
                    <Text>
                        <p>
                            The mission of <a href="https://www.svcsi.org" target="_blank" className="text-orange-600 font-semibold hover:underline">SVCSI</a> is to train underrepresented groups in cybersecurity (UNiSEC) and to increase their interest in cybersecurity as a career. Thus, <a href="https://www.svcsi.org" target="_blank" className="text-orange-600 font-semibold hover:underline">SVCSI</a> will provide a great opportunity for the UNiSEC groups. Please fill out your information in <a href="https://forms.gle/p2xwhZ1nWpRJahTW6" target="_blank" className="text-orange-600 font-semibold hover:underline">Google Form</a> if you want to request the registration fee waive. The applicant must be a student and a member of UNiSEC based on the NSF definition for underrepresented groups present at <a href="https://www.nsf.gov/statistics/2017/nsf17310/digest/introduction/" target="_blank" className="text-orange-600 font-semibold hover:underline">https://www.nsf.gov/statistics/2017/nsf17310/digest/introduction/</a> .
                        </p>
                        <p>
                            You can register for this conference through <a href="https://www.cs-room.com" target="_blank" className="text-orange-600 font-semibold hover:underline">CS ROOM</a> after signing up by using your university email or other email addresses. <a href="https://www.cs-room.com" target="_blank" className="text-orange-600 font-semibold hover:underline">CS ROOM</a> is our <a href="https://www.svcsi.org" target="_blank" className="text-orange-600 font-semibold hover:underline">SVCSI</a> website to host events. <a href="https://www.svcsi.org" target="_blank" className="text-orange-600 font-semibold hover:underline">SVCSI</a>, a 501(c)(3) non-profit organization, hosts the annual SVCC conference.
                        </p>
                    </Text>
                    <HeadingRow className="mt-8 text-center">
                        <Heading>Group Ticket Purchases</Heading>
                    </HeadingRow>
                    <Text>
                        <p style={{ fontSize: "16px" }}>Please go to <a href="https://cs-room.com/event/bp/617faf490dce1864bac63c61" target="_blank" className="text-orange-600 font-semibold hover:underline">https://cs-room.com/event/bp/617faf490dce1864bac63c61</a> and enter the number of tickets that you want to purchase for your group in STEP 1 as shown in the picture. Please fill out the form for each step from STEP 2 to 4. And then you can pay through your PayPal account or your credit card. We will contact you when we receive your payment with your registered email address.</p>
                        <p>
                            <img src="/img/Steps1.png" />
                        </p>
                        <p>
                            <img src="/img/Steps2.png" />
                        </p>
                    </Text>
                    <HeadingRow className="mt-8">
                        <Heading>Cancellation Policy</Heading>
                    </HeadingRow>
                    <Text style={{ fontSize: "16px" }}>
                        <p>
                            Because of the tight schedules, the registration fee is not refundable. We appreciate
                            your donation to <a href="http://svcsi.org/" target="_blank" className="font-semibold text-orange-600 hover:underline">SVCSI</a>, a 501(c)(3) non-profit organization (Public Charity) that hosts this annual SVCC conference.
                        </p>
                    </Text>
                    <div className="mt-8">
                        <PrimaryButton as="a" target="_blank" href="https://cs-room.com/event/bp/617faf490dce1864bac63c61">Register</PrimaryButton>
                    </div>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
