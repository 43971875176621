import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import styled from "styled-components";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import Speakers from "components/features/VerticalWithAlternateImageAndText.js";
import TabGrid from "components/cards/TabCardGrid.js";
import SilverSponsors from "components/cards/SilverSponsorsCardGrid.js";
import PatronSponsors from "components/cards/PatronSponsorsCardGrid.js";
// import TabGrid from "components/cards/TabCardGrid.js";
import ProfileThreeColGrid from "components/cards/ProfileThreeColGrid";
import TShirts from "components/cards/TShirts";
import { keyNoteSpeakers, specialTalkSpeakers } from "../helpers/data";
import MainFeature1 from "components/features/TwoColWithButton.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Subheading = tw.span`uppercase tracking-wider text-sm`;
  const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
    props.buttonRounded && tw`rounded-full`
  ]);

  return (
    <AnimationRevealPage>
      <Hero />
      <MainFeature1
        heading={"About SVCC"}
        description="The annual Silicon Valley Cybersecurity Conference (SVCC) focuses on research in
        dependability, reliability, and security to address cyber-attacks, vulnerabilities, faults,
        and errors in networks and systems. This conference is a forum to present research in
        robustness and resilience in a wide spectrum of computing systems and networks. All
        aspects of the research and practice of applied security are within the scope of this
        conference. Relevant topics include innovative system design, protocols, and
        algorithms for detecting and responding to malicious threats in dependable and secure
        systems and networks including experimentation and assessment."
        buttonRounded={false}
        primaryButtonText="Register Now"
        imageSrc="img/wordcloud1.png"
        primaryButtonUrl="https://www.eventbrite.com/e/3rd-international-conference-of-silicon-valley-cybersecurity-tickets-363459145087"
        disabled={false}
      />

      {/* Keynote Speakers */}
      <ProfileThreeColGrid heading="Keynote Speakers" speakers={keyNoteSpeakers} />
      <ProfileThreeColGrid heading="Special Talk Speakers" speakers={specialTalkSpeakers} />

      {/* <TShirts heading="Fund Raising" 
      subheading="Buy the official 2020 Silicon Valley Cybersecurity Institute shirt to help support underrepresented groups in cybersecurity!"
      tshirts = {fundRaising}/> */}

      {/* Sponsors*/}
      {/* <TabGrid
        heading={
          "Sponsors"
        }
      /> */}

      <SilverSponsors />
      <PatronSponsors />

      {/* Zoom Background and Brochures */}

      <div style={{ textAlign: "center", margin: "50px" }}>
        <PrimaryButton buttonRounded={true} as="a" href="downloads">
          Download Zoom Background
            </PrimaryButton>
      </div>

      {/* <ContactUsForm /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
