import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import CallForPaperText from './CallForPaperText';
import Head from "./Header";
import Header from "../../components/headers/light.js";
import Page from "./TwoColumnWithVideo";
import Tracks from "./Tracks";
import Footer from "components/footers/MiniCenteredFooter.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { LinkedinFilled } from '@ant-design/icons';
import { Popover } from "antd";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <AnimationRevealPage>
                <Header />
                {/* <CallForPaperText/> */}
                <Page />
                <Tracks />
                <HeaderContainer className="pb-16">
                    <Heading>Contact</Heading>
                    <Description>The organizing committee of SVCC 2022 can be reached via email at <a href="mailto:SVCC.CONF@svcsi.org" style={{ color: "blue" }}>SVCC.CONF@svcsi.org</a></Description>
                </HeaderContainer>
                <Footer />
            </AnimationRevealPage>
        );
    }
}

export default Main;