import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";

import { ReactComponent as GithubIcon } from "images/github-icon.svg";
//import {keyNoteSpeakers} from "../../helpers/data"
const HeadingSpeakers = tw(
  SectionHeading
)`mt-4 font-black text-black text-left text-3xl sm:text-3xl lg:text-3xl text-center md:text-center leading-tight`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto px-1`;
const Card = tw.div`mt-24 w-full sm:w-1/3 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-cover bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`;
export default ({
  heading = "Keynote Speakers",
  subheading = "Our",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  speakers = []
  // cards = [
  // keyNoteSpeakers.map.
  // ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {/* {subheading && <Subheading>{subheading}</Subheading>} */}
          {heading && <HeadingSpeakers>{heading}</HeadingSpeakers>}
          {/* {description && <Description>{description}</Description> } */}
        </HeadingContainer>
        <Cards>
          {speakers.map((card, index) => (
            <Card key={index}>
              <a href={card.redirectURI} ><CardImage imageSrc={card.imageSrc} /></a>
              <CardContent>
                <a href={card.redirectURI}  ><span className="name">{card.name}</span></a>
                <div className="text-center"><span className="position">{card.position}</span></div>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url} target="_blank">
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
