import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings";
import Timeline from './timeline';
// import Commitee from './commitee';
import { PrimaryButton } from "../../components/misc/Buttons.js";
import Footer from "../../components/footers/MiniCenteredFooter.js";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";
import { FaAward } from "react-icons/fa";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${'' /* ${tw`mt-2 leading-loose`} */}
    ${tw`mt-2 leading-normal`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-outside`}
    li {
      ${tw`ml-12`}
      p {
        ${'' /* ${tw`mt-0 inline leading-normal`} */}
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const datathonChairMembers = [
    {
        imageSrc: require("../../images/datathon/jorjetaJetcheva.jpg"),
        position: "Assistant Professor, San Jose State University",
        name: "Jorjeta Jetcheva",
        links: [
            {
                url: "https://www.linkedin.com/in/jorjetajetcheva/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/carlosRojas.jpeg"),
        position: "Assistant Professor, San Jose State University",
        name: "Carlos Rojas",
        links: [
            {
                url: "https://www.linkedin.com/in/carlos-rojas-27224520/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/malekBenSalem.jpeg"),
        position: "Director, Accenture",
        name: "Malek Ben Salem",
        links: [
            {
                url: "https://www.linkedin.com/in/malek-ben-salem/",
                icon: LinkedinIcon
            }
        ]
    },
];

const judges = [
    {
        imageSrc: require("../../images/datathon/alvaro.jpeg"),
        position: "Associate Professor, UC Santa Cruz",
        name: "Álvaro Cárdenas",
        links: [
            {
                url: "https://www.linkedin.com/in/%C3%A1lvaro-c%C3%A1rdenas-7817313/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/winnie.jpeg"),
        position: "Director, PwC",
        name: "Dr. Winnie Cheng",
        links: [
            {
                url: "https://www.linkedin.com/in/winniewcheng/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/davidKim.jpeg"),
        position: "Assistant Professor, Georgia State University",
        name: "David Kim",
        links: [
            {
                url: "https://www.linkedin.com/in/donghyun-david-kim-6273b836/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/mahimaSuresh.jpeg"),
        position: "Assistant Professor, San Jose State University",
        name: "Mahima Agumbe Suresh",
        links: [
            {
                url: "https://www.linkedin.com/in/mahimaas/",
                icon: LinkedinIcon
            }
        ]
    },
    {
        imageSrc: require("../../images/datathon/nima.jpeg"),
        position: "Assistant Professor, San Jose State University",
        name: "Nima Karimian",
        links: [
            {
                url: "https://www.linkedin.com/in/nima-karimian-2966ba53/",
                icon: LinkedinIcon
            }
        ]
    },
];

export default ({ headingText = "Terms And Condition" }) => {
    return (
        <AnimationRevealPage>
            <Header />
            <Container >
                <ContentWithPaddingXl>
                    <div className="">
                        <h1 style={{ "color": "#6415FF", fontSize: "2.5rem", fontWeight: "700", textAlign: "center", marginTop: "0px", marginLeft: "2px" }}>
                            CyberWarrior Competition
                        </h1>
                    </div>
                    {/* <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow> */}
                    <Text>

                        <p>SVCC virtually hosts an annual cybersecurity competition, called CyberWarrior. The
                        CyberWarrior will have two challenging parts: <b>Capture-the-Flag(CTF) and a Packet
                        Tracer(PT) challenge</b>. CyberWarrior will be held as a part of the SVCC 2022 event online
                        and will last for five hours: CTF for two hours and PT for two hours including one hour break
                        time. The goal of the Competition is to create an enjoyable and interactive learning experience to improve cybersecurity skills through problem solving techniques.</p>

                        <p>The CTF competition is designed for everybody from high school students who have no
                        previous course experience to the college students study and research in cybersecurity to
                        professional cybersecurity practitioners who have more experience in cybersecurity, as there
                        are many flags with varying difficulties/points. A short demonstration will be conducted before
                        the Competition to introduce the game rules and how to navigate in the competition system.</p>

                        <p>The Packet Tracer competition is designed for everybody who is interested in network security.
                        The competition will utilize the Packet Tracer tool designed by Cisco. Given a problem for
                        Packet Tracer, each participant will solve the problem and submit their work before the deadline
                        for submission.</p>

                        <p>
                            In particular, we encourage underrepresented students (Hispanics, women, Mexicans, etc.) to
                            participate in this CyberWarrior competition.
                        </p>

                        <h1>Who can participate?</h1>
                        <p></p>
                        <ul>
                            <li>
                                Basically anyone can participate in this competition. We divide three tiers: high school
                                tier, college tier, and professional tier. The professional tier includes industry people,
                                master and doctoral students.
                            </li>
                            <li>
                                This is an individual game, so there is no need to form your team to participate.
                            </li>
                            <li>
                                We welcome students from underepresented groups to participate in this competition. Underrepresented groups include, and are not limited to those who are women,
                                African American, LatinX, American Indian or Alaska Native. You can also refer to
                                the definition of underrepresented groups in STEM used by the National Science
                                Foundation (NSF) <a className="text-blue-600 hover:text-blue-800 hover:underline visited:text-purple-900" href="https://www.nsf.gov/statistics/2017/nsf17310/digest/introduction/" target="_blank">here</a>.
                            </li>
                            <li>
                                Conference registered users can participate in this event for free. But non-registered
                                users need to pay a $50 registration fee. There is only a $10 registration fee to
                                participate in this competition for high school students and college students (We will contact you to check your student’s status).
                            </li>
                        </ul>

                        <h1>Timeline</h1>
                        <Timeline />

                        <h1>Evaluation</h1>
                        <p>
                            The competition committee will rank each participant based on the total scores by combining the
                            score of your PT submission and your CTF score. To standardize the final competition scores,
                            we will take 50% of the CTF scores and 50% of the PT score to make a 100%. We will discover
                            our final score sheet for participants. The detailed scoring system will be introduced during the
                            training session. The CTF competition utilizes an auto grade system when every time a player
                            submits a flag it immediately grades it. The CTF hosts will be around during the game, if a
                            player appeals the grade, the host can check and make necessary changes to override the
                            auto-graded score.
                        </p>
                        <h1>Competition Policies and Rules</h1>
                        <p></p>
                        <ul>
                            <li>
                                The CTF Competition has a variety of challenges for participants to solve, feel free to choose any challenge that is available to you.
                                 <ul style={{ "list-style-type": "circle" }}>
                                    <li>
                                        Note that, each of your solves may unlock new challenges, so you should be
                                        aware of what is currently available and what are the new ones.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                The minimum hardware requirements include a laptop that can access the Internet with
                                a working browser installed (FireFox, Chrome, Safari, Edge, etc.). You will also need
                                your smartphone.
                            </li>
                            <li>
                                All publicly available information can be utilized, this includes search results from
                                Google, publicly accessible websites, etc.
                            </li>
                            <li>
                                Competitors will be disqualified for awards if cheating cases are found.
                            </li>
                            <li>
                                Keep in mind that it is an individual competition. <b>No collaboration is allowed during the competition time</b>.
                            </li>
                        </ul>

                        {/* <h1>Prizes</h1>
                        <p></p>
                        <ul>
                            <li>
                                The top selected teams ($1,000 per team)
                            </li>
                            <li>
                                5 Honorable Mention Award ($100 per team)
                            </li>
                        </ul>
                        <p>
                            Depending on the funding, we select around 6 teams as finalists based on the Kaggle leaderboard ranking with their submitted solutions. It will include the top 3 undergraduate teams and the top 3 among the remaining teams (can be graduate or undergraduate). Each of the 6 finalist teams will be awarded $1,000 total (to be split evenly among team members).
                        </p> */}
                        <h1>CyberWarrior Awards</h1>
                        <p>
                            We will divide three groups to select winners in this competition: high school students,
                            undergraduate students, and others. We will provide a cash award and an award certificate for
                            each awardee.
                        </p>
                        <p>
                            Note that, we will not do the international wire transfer. We will issue a SVCSI check to the
                            physical address of the USA only. We will contact the winners after finishing the conference
                            within 1 week. If you do not receive an email about your award, please contact us at <a href="mailto:svcc.conf@svcsi.org" className="text-red-600 font-semibold hover:text-blue-800 hover:underline visited:text-purple-900">event.manager@svcsi.org</a>
                        </p>
                        <p>List of awards:</p>
                        <ul>
                            <li>
                                <b>Top 5 professional level participants ($300)</b>
                            </li>
                            <li>
                                <b>Top 10 undergraduate student participants ($300)</b>
                            </li>
                            <li>
                                <b>Top 10 high school student participants ($200)</b>
                            </li>
                        </ul>
                        {/* <p>
                            The # of awards can be subjected to the number of participant teams and each individual contribution to this competition. Judges will evaluate each team and individual contributions too.
                        </p> */}

                        <h1>Registration</h1>
                        <p className="font-bold text-red-600">All of the participants need to register through <a href="https://www.eventbrite.com/e/3rd-international-conference-of-silicon-valley-cybersecurity-tickets-363459145087" className="hover:text-blue-800 underline" target="_blank">Eventbrite</a>. The registrations will open in June, 2022. All the competitors need to register.</p>
                        <p className="font-bold text-xl">Registration Fees:</p>
                        <ul>
                            <li className="font-semibold">High School Students and Undergraduate Students (Non-registered users for the SVCC conference): <span className="font-bold text-red-600">$10</span> (We will contact you to check your student’s status.)</li>
                            <li className="font-semibold">Conference registered users can participate in this event for free. But <p className="font-bold text-red-600">non-registered users need to pay a $50 registration fee.</p></li>
                        </ul>
                        <br />
                        <PrimaryButton buttonRounded="true" as="a" href="https://www.eventbrite.com/e/3rd-international-conference-of-silicon-valley-cybersecurity-tickets-363459145087" target="_blank">
                            Register now
                        </PrimaryButton>
                        <br />

                        <h1>Competition Committee:</h1>
                        <p></p>
                        <ul>
                            <li>Sang-Yoon Chang, Assistant Professor, UCCS</li>
                            <li>Michael Wang, CTO, Vitapoly Inc.</li>
                            <li>Kelei (Jackie) Zhang, PhD Student at UCCS & Instructor at FHSU</li>
                            <li>Fabio Di Troia, San Jose State University</li>
                            <li>Jinoh Kim, Texas A&M University-Commerce</li>
                        </ul>
                        <p />
                        <h1>Competition Sponsors</h1>
                        <div className="flex-row flex justify-evenly mt-10 items-center">
                            <div className="flex flex-col justify-start items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/2/2f/Google_2015_logo.svg" width="400px" height="auto" />
                                <span className="font-bold text-center text-xl text-gray-600 mt-8">Silver <FaAward className="w-6 h-6 inline ml-2" /></span>

                            </div>
                            <div className="flex flex-col justify-start items-center">
                                <img src="https://1000logos.net/wp-content/uploads/2016/11/Cisco-logo-768x405.png" width="250px" height="auto" />
                                <span className="font-bold text-center text-xl text-green-500 mt-8">Patron <FaAward className="w-6 h-6 inline ml-2" /></span>

                            </div>
                        </div>
                        <br />
                        <p></p>
                        <br />
                        <p>
                            Please feel free to contact us at <a href="mailto:svcc.conf@svcsi.org" className="text-red-600 font-semibold">event.manager@svcsi.org</a> for any questions realted to the CyberWarrior competition.
                        </p>
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
