import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import Speakers from "pages/Speakers";
import SpecialTalkSpeakers from "pages/SpecialTalkSpeakers";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are custom building it */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
// import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";

// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
// import Features from "components/features/DashedBorderSixFeatures";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
// import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";

// import Pricing from "components/pricing/ThreePlans.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";

// import SliderCard from "components/cards/ThreeColSlider.js";
// import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
// import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
// import TabGrid from "components/cards/TabCardGrid.js";

// import Blog from "components/blogs/ThreeColSimpleWithImage.js";
// import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
// import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
// import Blog from "components/blogs/GridWithFeaturedPost.js";

// import Testimonial from "components/testimonials/TwoColumnWithImage.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
// import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/SingleCol.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

// import ContactUsForm from "components/forms/SimpleContactUs.js";
// import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
//
// import GetStarted from "components/cta/GetStarted.js";
// import GetStarted from "components/cta/GetStartedLight.js";
// import DownloadApp from "components/cta/DownloadApp.js";

// import Footer from "components/footers/SimpleFiveColumn.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/FiveColumnWithBackground.js";
// import Footer from "components/footers/FiveColumnDark.js";
// import Footer from "components/footers/MiniCenteredFooter.js";

/* Ready Made Pages (from demos folder) */
import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
import AgencyLandingPage from "demos/AgencyLandingPage.js";

// import Speakers from "pages/speakers/speakers";
import HackathonMain from "pages/hackathon/hackathonMain";
import NshTrack from "pages/hackathon/nshTrack";
import BshTrack from "pages/hackathon/bshTrack";
import Registration from "pages/Registration";
import Datathon from "pages/datathon/datathonMain";
import Tutorials from "pages/datathon/tutorialLinks";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import LoginPage from "pages/Login.js";
// import SignupPage from "pages/Signup.js";
// import PricingPage from "pages/Pricing.js";
// import AboutUsPage from "pages/AboutUs.js";
// import ContactUsPage from "pages/ContactUs.js";
// import BlogIndexPage from "pages/BlogIndex.js";
// import TermsOfServicePage from "pages/TermsOfService.js";
// import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import Unisec from "./components/unisec/Main";
import Dates from "./components/importantDates/Main";
import Organizers from "./components/organizers/Main";
import CallForPapers from "./components/callforpapers/Main";
import CallForSpecialSessions from "./components/specialSessions/Main";
import InstructionForAuthors from "./components/callforpapers/InstructionForAuthors";
import Sponsors from "./components/sponsors/Main";
import Submissions from "./components/submissions/Main";
import Agenda from "./components/agenda/Main";
import Schedule from "./components/agenda/Agenda";
import DistinguishedResearchForum from "./components/agenda/Speakers";
// import Tutorials from "./components/agenda/Tutorials";
import Downloads from './pages/Downloads';
import ResearchPapers from './pages/ResearchPapers';
import AcceptedPapers from "./pages/AcceptedPapers";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import hackathonMain from "pages/hackathon/hackathonMain";
import Raffle from "./components/raffle/Raffle";
import ComingSoon from "pages/ComingSoon";
import SpecialTalk from "./components/specialSessions/specialtalk";
import CyberSec from "./pages/cybersec/cybersecMain";

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/callforposters" exact component={Unisec} />
        <Route path="/dates" exact component={Dates} />
        <Route path="/organizers" exact component={Organizers} />
        <Route path="/callforpapers" exact component={CallForPapers} />
        <Route path="/sponsors" exact component={Sponsors} />
        <Route path="/submissions" exact component={Submissions} />
        {/* <Route path="/agenda" exact component={Agenda} /> */}
        <Route path="/program" exact component={Schedule} />
        <Route exact path="/">
          <AgencyLandingPage />
        </Route>
        <Route exact path="/speakers">
          <Speakers />
        </Route>
        <Route exact path="/specialTalkSpeakers">
          <SpecialTalkSpeakers />
        </Route>
        <Route exact path="/speakers/:speakerName" >
          <Speakers />
        </Route>
        <Route exact path="/specialTalkSpeakers/:speakerName">
          <SpecialTalkSpeakers />
        </Route>
        {/* <Route exact path="/hackathon" >
          <HackathonMain />
        </Route> */}
        {/* <Route exact path="/hackathon/nsh" >
          <NshTrack />
        </Route> */}
        {/* <Route exact path="/hackathon/bsh" >
          <BshTrack />
        </Route> */}
        {/* <Route exact path="/registration" >
          <Registration />
        </Route> */}
        {/* <Route exact path="/distinguishedResearch" >
          <DistinguishedResearchForum />
        </Route> */}
        {/* <Route exact path="/tutorials" >
          <Tutorials />
        </Route> */}
        <Route exact path="/downloads" >
          <Downloads />
        </Route>
        {/* <Route exact path="/raffle">
          <Raffle />
        </Route> */}
        <Route exact path="/instruction-for-authors" >
          <InstructionForAuthors />
        </Route>
        {/* <Route exact path="/accepted-papers" >
          <ResearchPapers />
        </Route> */}
        {/* <Route exact path="/accepted-papers/:paper" >
          <AcceptedPapers />
        </Route> */}
        {/* <Route exact path="/datathon">
          <Datathon />
        </Route> */}
        {/* <Route exact path="/datathon/tutorials">
          <Tutorials />
        </Route> */}
        <Route exact path="/callforposters">
        </Route>
        {/* <Route exact path="/callforspecialsessions">
          <CallForSpecialSessions />
        </Route> */}
        <Route exact path="/coming-soon" >
          <ComingSoon />
        </Route>
        <Route exact path="/specialtalk">
          <SpecialTalk />
        </Route>
        <Route exact path="/cyberwarrior">
          <CyberSec />
        </Route>
      </Switch>
    </Router>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
