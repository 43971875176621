import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import Ways from "./DashedBorderSixFeatures";

const HeaderContainer = tw.div`mt-0 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-3`}
  }

  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-500 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-4 first:mt-0 text-xs`}
    &:not(.mainFeature) {
      ${tw`text-gray-600`}
    }
  }
  .mainFeature {
    ${tw`text-xl font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;


export default ({
  subheading = "Pricing",
  heading = "Industrial Sponsors",
  description = "We are seeking industrial sponsors at the following levels",
  plans = null,
  primaryButtonText = "Buy Now"
}) => {
  const defaultPlans = [
    {
      name: "Silver",
      price: "$3000",
      duration: "Monthly",
      mainFeature: "Benefits",
      features: ["Recognition for sponsorship of Welcome Reception", "Sponsor logo on symposium signage", "Sponsor logo on advance marketing brochure", "Sponsor logo & link at event web site", "All sponsor attendee badges marked “Bronze Sponsor”"],

    },
    {
      name: "Platinum",
      price: "$10000",
      duration: "Monthly",
      mainFeature: "Benefits",
      features: ["Recognition for sponsorship of Proceedings", "Recognition for sponsorship of Welcome Reception", "Sponsor logo on symposium signage – 1st position", "Sponsor logo on advance marketing brochure – 1st position", "Sponsor logo & link at event web site – 1st position", "Up to three (4) complimentary registrations", "30-60 minute Virtual Industry Demo Session", "All sponsor attendee badges marked “Gold Sponsor”"],
      featured: true,
    },
    {
      name: "Gold",
      price: "$6000",
      duration: "Monthly",
      mainFeature: "Benefits",
      features: ["Recognition for sponsorship of Welcome Reception", "Sponsor logo on symposium signage – 2nd position", "Sponsor logo on advance marketing brochure – 2nd position", "Sponsor logo & link at event web site – 2nd position", "Up to two (2) complimentary registrations", "30-60 minute Virtual Industry Demo Session", "All sponsor attendee badges marked “Silver Sponsor”"],
      

    }
    
  ];

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
   
    
    css`
      background: silver;
      background: silver;
    `,
    css`
    background: #e5e4e2;
    background: #e5e4e2;
  `,
    css`
      background: #d6b91a;
      background-image: #d6b91a;
    `,
  ];

  return (
    <Container>
      <HeaderContainer>
        <Heading >{heading}</Heading>
        {description && <Description>{description}</Description>}
      </HeaderContainer>
      <PlansContainer>
        {plans.map((plan, index) => (
          <Plan key={index} featured={plan.featured}>
            {!plan.featured && <div className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />}
            <PlanHeader>
              <span className="name">{plan.name}</span>
              <span className="price">{plan.price}</span>
              {/* <span className="duration">{plan.duration}</span> */}
            </PlanHeader>
            <PlanFeatures>
              <span className="feature mainFeature">{plan.mainFeature}</span>
              {plan.features.map((feature, index) => (
                <span key={index} className="feature">
                  {feature}
                </span>
              ))}
            </PlanFeatures>
            <PlanAction>
            <a href="https://svcsi.org/donate" target="_blank"><BuyNowButton href="#pay" css={highlightGradientsCss[index]}>Sponsor</BuyNowButton></a>
            </PlanAction>
          </Plan>
        ))}
        <DecoratorBlob />
      </PlansContainer>
      <Ways />
    </Container>
  );
};
