import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import { ReactComponent as PlayIcon } from "feather-icons/dist/icons/play-circle.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import DesignIllustration from "../../images/design-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-10 md:py-12 py-20 md:pb-4`;
const LeftColumn = tw.div`relative lg:w-8/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto lg:pb-24`;
const Heading = tw.h1`font-black text-primary-500 text-xl md:text-3xl leading-snug max-w-3xl mb-2`;
const HeadingMain = tw.h1`font-black text-primary-500 text-2xl md:text-5xl leading-snug max-w-3xl mb-2`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;
const SubParagraph = tw.p`my-5 lg:my-8 text-sm text-gray-600 mx-auto lg:mx-0`;
const HeadingRow = tw.div`flex`;
const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const WatchVideoButton = styled.button`
  ${tw`mt-4 sm:mt-0 sm:ml-8 flex items-center text-secondary-300 transition duration-300 hocus:text-primary-400 focus:outline-none`}
  .playIcon {
    ${tw`stroke-1 w-12 h-12`}
  }
  .playText {
    ${tw`ml-2 font-medium`}
  }
`;
const Text = styled.div`
  ${tw`text-base text-gray-800 mb-8`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10`}
`;

const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none`}
  }
  .content {
    ${tw`w-full lg:p-16`}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;

export default ({
  heading = "CALL FOR PAPERS",
  description = "The deadline will be on November 30 at 11:59PM PDT and the poster presenters much register to the conference by December 10, 2020.",
  primaryButtonText = "Submit Now",
  primaryButtonUrl = "#",
  watchVideoButtonText = "Watch Video",
  watchVideoYoutubeUrl = "https://www.youtube.com/embed/_GuOjXYl5ew",
  imageSrc = DesignIllustration,
  imageCss = null,
  imageDecoratorBlob = true,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <Container className="mx-4">
        <TwoColumn>
          <LeftColumn>
            <HeadingMain>{heading}</HeadingMain>
            <SubParagraph style={{ textAlign: "justify", textJustify: "interWord" }}>
              The Silicon Valley Cybersecurity Conference (SVCC) is an annual  international conferencein cybersecurity held at the heart of Silicon Valley, which is supported by the Silicon Valley Cybersecurity Institute (SVCSI). SVCC focuses on research in dependability, reliability, andsecurity   to   address   cyber-attacks,   vulnerabilities,   faults,   and   errors   in   networks   and systems. This conference is a forum to present research in robustness and resilience in a wide spectrum of computing systems and networks. All aspects of the research and practice of applied security are within the scope of this conference. Relevant topics include innovative system design, protocols, and algorithms for detecting, preventing, mitigating, and responding to malicious threats in dependable and secure systems and networks including experimentation and assessment. The topics of interest are in the security of hardware, software, networks, clouds, cyber-physical systems, socio-technical systems, blockchain, and healthcare.
                </SubParagraph>
            {/* <Paragraph>{description}</Paragraph> */}
            {/* <Actions>
              <PrimaryButton as="a" target="_blank" href="https://easychair.org/conferences/?conf=svcc2020">{primaryButtonText}</PrimaryButton>
            </Actions> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                css={imageCss}
                src={imageSrc}
                alt="Hero"
              />
              {imageDecoratorBlob && <DecoratorBlob2 />}
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            <ResponsiveVideoEmbed url={watchVideoYoutubeUrl} tw="w-full" />
          </div>
        </StyledModal>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Important Dates</Heading>
          </HeadingRow>
          <Text>
            <ul>
              {/* <li> Abstract deadline: <b>May 20, 2022</b></li> */}
              <li>Paper submission: <b>July 15, 2022 at Midnight (Your Timezone)
               {/* (<span className="text-red-600">Firm Deadline</span>) */}
              </b></li>
              <li> Author notification: <b>July 29, 2022</b></li>
              <li>Camera-Ready Deadline: <b>August 10, 2022</b></li>
            </ul>
          </Text>
          <HeadingRow>
            <Heading>Program Chairs</Heading>
          </HeadingRow>
          <Text>
            <ul>
              <li>Luis Bathen, IBM Research</li>
              <li>Gokay Saldamli, San Jose State University</li>
              <li>Xiaoyan Sun, California State University (Sacramento)</li>
            </ul>
          </Text>
          <HeadingRow>
            <Heading>Paper Submission</Heading>
          </HeadingRow>

          <Text className="text-justify">
            The authors need to submit their original papers to <a href="https://easychair.org/conferences/?conf=svcc2022" target="_blank"><b><u>EasyChair</u></b></a>.
            <p />
            <b className="text-lg">Two different types of papers can be submitted:</b>
            <p />
            <ul>
              <li>
                <b>
                  <u>Regular papers: 16 pages in the CCIS format.</u>
                </b>
                &nbsp;(It is equivalent to 6-7 pages IEEE double-column conference proceeding format.)
              </li>
              <li>
                <b>
                  <u>Short papers: 12 pages in the CCIS format.</u>
                </b>
                &nbsp;(It is equivalent to 4-5 pages IEEE double-column conference proceeding format.)
              </li>
              <li>
                <b>
                  <u>Poster Abstract: 3 - 4 pages on the CCIS Format.</u>
                </b>
                &nbsp;(It will not be included in the CCIS book at Springer, but your poster can be published on our SVCC 2021 website all the time. However, if you want to include your poster in the CCIS book at Springer, you must be extended to 12 pages. <b><u>The 12 pages are a basic requirement to publish your work at Springer.</u></b> Also, you need to register as a paper author rate instead of the poster author registration rate.)
              </li>
            </ul>
            {/* Two different types of papers   can   be   submitted,   and   the   specified   page   lengths   include   tables,   figures, references, and appendices:  Regular   papers   (16   pages   single-column   format)  and Short papers (8 pages single-column format). All papers must be written in English. Manuscripts   must   include   a   title,   an   abstract   with   200-250   words,   and   a   list   of   4-6 keywords.   All   papers   must   be   prepared   in   the
                        <b> Springer   LNCS   single-column format. </b>
                        SVCC2021 uses a double-blind review policy. Authors are required to remove their   names,   affiliation(s),   and   other   identifying   information   from   the   header   of   the manuscript.   This   also   includes   meta-data   in   the   submitted   document   as   well   asacknowledgment   sections. Papers   that   do   not   meet   these   anonymization requirements may be rejected without further review. */}
            {/* See <a className="text-orange-500" href = "https://www.svcsi.online/sv-cybersecurity-conf">https://www.svcsi.online/sv-cybersecurity-conf</a> for additional details. */}
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </>
  );
};
