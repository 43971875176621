import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container } from "../misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../headers/light.js";
import Footer from "../footers/MiniCenteredFooter.js";
import { SectionHeading } from "../misc/Headings";
import FAQ from "../faqs/SingleCol.js";
import Pricing from "../pricing/ThreePlansWithHalfPrimaryBackground.js";
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-2`;
const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto pt-8 pb-24 lg:pt-8 lg:pb-24`;
const PrimaryButton = tw.button`font-bold px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Text = styled.div`
  ${tw`text-lg text-gray-800 mb-8`}
  p {
    ${tw`my-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "IOT CYBERSECURITY HACKATHON FOR STUDENTS" }) => {
    return (
            <Container style={{ textAlign: "justify", textJustify: "interWord" }}>
                <ContentWithPaddingXl>
                    
                    <HeadingRow>
                        <Heading>Important Dates</Heading>
                    </HeadingRow>
                    <Text>
                        <ul>
                            <li>Paper submission: <b>August 15, 2021 at Midnight (PDT)</b></li>
                            <li> Author notification: <b>October 15, 2021</b></li>
                            <li>Camera-Ready Deadline: <b>November 15, 2021</b></li>
                        </ul>
                    </Text>
                    <HeadingRow>
                        <Heading>Program Chairs</Heading>
                    </HeadingRow>
                    <Text>
                        <ul>
                        <li>Sang-Yoon Chang, University of Colorado Colorado Springs</li>
                        <li>Luis Bathen, IBM Research</li>
                        <li>Fabio Di Troia, San Jose State University</li>
                        </ul>
                    </Text>
                    <HeadingRow>
                        <Heading>Scope and Motivation</Heading>
                    </HeadingRow>
                    <Text>
                    The Silicon Valley Cybersecurity Conference (SVCC) is an annual  international conferencein cybersecurity held at the heart of Silicon Valley, which is supported by the Silicon Valley Cybersecurity Institute (SVCSI). SVCC focuses on research in dependability, reliability, andsecurity   to   address   cyber-attacks,   vulnerabilities,   faults,   and   errors   in   networks   and systems. This conference is a forum to present research in robustness and resilience in a wide   spectrum   of   computing   systems   and   networks.   All   aspects   of   the   research   and practice   of   applied   security   are   within   the   scope   of   this   conference.   Relevant   topics include   innovative   system   design,   protocols,   and   algorithms   for   detecting, preventing, mitigating,  and responding  to  malicious threats in dependable and secure systems and networks  including   experimentation   and   assessment.The   topics   of  interest  are   in  the security of hardware, software, networks, clouds, cyber-physical systems, socio-technical systems, blockchain, and healthcare.
                    </Text>
                    <HeadingRow>
                        <Heading>Topics of Interest</Heading>
                    </HeadingRow>
                    <Text>
                    Authors are invited to submit original papers on the current areas of emphasis, but not limited to:
                        <p style={{ fontWeight: "700" }}>Network Security:</p>
                        <ul>
                        <li>ML/DL techniques for Internet-of-Things, wireless networks, and open networking</li>
                        <li>Software-defined networking and network function virtualization</li>
                        <li>Edge-computing security and privacy-Attack detection, prevention, or mitigation</li>
                        <li>Anonymous communications</li>
                        <li>Cryptography for network security</li>
                        <li>Identity management</li>
                        <li>Firewall technologies</li>
                        <li>Formal trust models and security modeling</li>
                        <li>Mobile and wireless network security</li>
                        <li>Internet-of-Things security and privacy</li>
                        </ul>

                        <p style={{ fontWeight: "700" }}>Blockchain Security:</p>
                        <ul>
                        <li>Distributed Ledger Technologies (DLT)</li>
                        <li>Security and privacy in decentralized applications (dAPPs)/DLT/Blockchain</li>
                        <li>Distributed consensus protocols</li>
                        <li>Threats on blockchains</li>
                        <li>Blockchain theory and algorithms</li>
                        <li>Smart Contracts -Infrastructures for dApps</li>
                        <li>Communication protocols and standards for dApps</li>
                        <li>Decentralized identity and identity management</li>

                        </ul>

                        <p style={{ fontWeight: "700" }}>System and Hardware Security</p>
                        <ul>
                        <li>Security primitives in system architecture and systems</li>
                        <li>Side-channel attacks and mitigation-Smartphones and smart devices</li>
                        <li>Firmware security in Internet-of-Things-Cyberattacks in systems</li>
                        <li>Trusted execution environments-Anti-counterfeit, anti-tamper-Hardware obfuscation</li>
                        <li>FPGA design and security</li>
                        <li>SoC platform security-Biometric Security</li>
                        <li>Cryptographic processors</li>
                        <li>True random and pseudo random number generators</li>

                        </ul>

                        <p style={{ fontWeight: "700" }}>Software Security:</p>
                        <ul>
                        <li>Secure software design/architecture</li>
                        <li>Secure software practices</li>
                        <li>Security requirements engineering</li>
                        <li>Security analysis tools and methods</li>
                        <li>Security evaluation criteria and methodology</li>
                        <li>Security education and training-Vulnerability, threat, and risk analysis</li>
                        <li>Malware detection/intrusion detection</li>
                        <li>Obfuscation and software security</li>
                        <li>Usability of secure/security software</li>
                        <li>Security awareness for software developers</li>
                   
                        </ul>

                       
                      
                     </Text>
                     <HeadingRow>
                        <Heading>Paper Submission</Heading>
                    </HeadingRow>
                      
                    <Text>
                        The authors need to submit their original papers to  EasyChair. Two different types of papers   can   be   submitted,   and   the   specified   page   lengths   include   tables,   figures, references, and appendices:  Regular   papers   (16   pages   single-column   format)  and Short papers (8 pages single-column format). All papers must be written in English. Manuscripts   must   include   a   title,   an   abstract   with   200-250   words,   and   a   list   of   4-6 keywords.   All   papers   must   be   prepared   in   the  
                        <b> Springer   LNCS   single-column format. </b>
                        SVCC2021 uses a double-blind review policy. Authors are required to remove their   names,   affiliation(s),   and   other   identifying   information   from   the   header   of   the manuscript.   This   also   includes   meta-data   in   the   submitted   document   as   well   asacknowledgment   sections. Papers   that   do   not   meet   these   anonymization requirements may be rejected without further review. 
                        {/* See <a className="text-orange-500" href = "https://www.svcsi.online/sv-cybersecurity-conf">https://www.svcsi.online/sv-cybersecurity-conf</a> for additional details. */}
                    </Text>
                   </ContentWithPaddingXl>
            </Container>
    );
};
