import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings.js";
import FatimaRiveria from "images/keynoteSpeakers/FatimaRiveria.jpg";
import NathalieBaracaldo from "images/keynoteSpeakers/NathalieBaracaldo.jpg";
import DomenicForte from "images/keynoteSpeakers/DomenicForte.jpeg";
import Footer from "components/footers/MiniCenteredFooter.js";
import KimDoowon from "images/specialTalkSpeakers/KimDoowon.jpeg";
import EnginArslan from "images/specialTalkSpeakers/EnginArslan.jpeg";
import EricChanTin from "images/specialTalkSpeakers/EricChanTin.jpeg";
import YousungKang from "images/specialTalkSpeakers/YousungKang.jpg";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const HeadingMain = tw.h1`font-black text-primary-500 text-2xl md:text-5xl leading-snug max-w-3xl`;
const Heading = tw(HeadingTitle)`mt-4 font-black text-left text-3xl sm:text-2xl lg:text-2xl text-center md:text-left leading-tight`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const TalkDescription = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-16 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const SmallCard = styled.div(props => [
    tw`mt-24 md:flex-col justify-center items-center w-9/12`,
]);
const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const BigImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-1000 md:h-1000 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const PresentationTitle = tw.h4`text-xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {

    const cards = [
        {
            imageSrc: KimDoowon,
            title: "Kim Doowon",
            description: "Doowon Kim is an Assistant Professor in the Department of Electrical Engineering and Computer Science (EECS) at the University of Tennessee, Knoxville (UTK). He joined UTK in Fall 2020 after he received his Ph.D. in Computer Science from the University of Maryland, College Park. He has worked on cyber security, including authentication (Public Key Infrastructures), systems security, malware analysis, Web, etc. His cyber security works have appeared at top-tier security conference venues, including IEEE Security & Privacy (S&P), ACM Conference on Computer and Communications Security (CCS), USENIX Security, and the Web Conf (formerly WWW). He was awarded the NSA Best Scientific Cybersecurity Paper (2017) and the Ann G. Wylie Dissertation Fellowship (2019).",
            talkTitle: "Understanding of the security threats in the software supply chain ecosystem",
            abstract: "A software supply chain is a complex series of software development processes required to build a software artifact. The processes are logically and tightly chained to each other process including coding, building, packaging/code-signing, and releasing. Such a chain is called a software supply chain. Unfortunately, the complex series of steps have led to an increase in the attack surfaces, and the software supply chain ecosystem has suffered supply chain attacks (e.g., SolarWinds). To this end, we address security problems and challenges in each development process by better understanding the stakeholders of each process with data-driven and human-centered perspectives.",
            isOnlySpeaker: true

        }, {
            imageSrc: EnginArslan,
            title: "Engin Arslan",
            description: "Dr. Engin Arslan is an Assistant Professor at the Department of Computer Science and Engineering at the University of Nevada, Reno (UNR) since 2017. He received his Ph.D. from University at Buffalo in 2016 and worked at National Science for Supercomputing Applications (NCSA) as a postdoctoral research associate for one year after graduation. His research interests include high-performance computing and networking, computer networks, file systems, edge and cloud computing, storage systems, and applied machine learning. His work in these areas is published at top conferences and journals including IEEE/ACM Supercomputing, USENIX NSDI, IEEE/ACM CCGrid, IEEE Cluster, and IEEE Transactions on Parallel and Distributed Systems (TPDS). He is also the recipient of several prestigious grants including NSF CRII, NSF Campus Cyberinfrastructure (CC*), and NSF CAREER. Dr. Arslan also serves in several committees including the editorial board of IEEE TPDS and the Cyberinfrastructure Committee of UNR.",
            isOnlySpeaker: true,
            talkTitle: "Flood Control: TCP-SYN Flood Detection using OpenFlow Port Statistics",
            abstract: "As cyber attacks are becoming new normal, it is increasingly important to develop effective solutions to defend networks against attacks. A prominent cyberattack that can compromise networks is TCP-SYN floods, which can exhaust network resources by initiating too many fraudulent TCP connections. Previous efforts to detect SYN Flood attacks mainly rely on statistical methods to process mirrored traffic or flow statistics. Thus, they either incur high overhead (in the case of port mirroring) or lead to low accuracy (in the case of using flow statistics). In this paper, we propose a machine learning (ML)-enabled TCP-SYN flood detection framework using Openflow port statistics. We demonstrate that ML models such as Random Forest classifiers can differentiate normal traffic from SYN flood traffic with up to 98% accuracy. We also introduce a novel threat localization technique that can pinpoint where the attack traffic originates in the network."
        },
        {
            imageSrc: EricChanTin,
            title: "Eric Chan-Tin",
            isOnlySpeaker: true,
            description: "Dr. Eric Chan-Tin is currently an associate professor in the Department of Computer Science, the lead of the Center for Cybersecurity and Privacy, and the PI for the NSA/DHS Center of Academic Excellence in Cyber Defense at Loyola University Chicago. Previously, he was an associate professor in the Computer Science Department at Oklahoma State University. He received his Ph.D. degree from the University of Minnesota and his B.A. from Macalester College. His research areas are in network security, distributed systems, privacy, anonymity, and at the intersection of cybersecurity and social sciences. He has published over 45 peer-reviewed papers, including publications at top conferences and journals such as ACM CCS, NDSS, ACM TISSEC, and IEEE TIFS. In 2020, he was recognized as a Master Researcher in the College of Arts and Sciences.",
            talkTitle: "Take this Personality Quiz to find your Password Type",
            abstract: "This talk will explore how different personalities create passwords of varying strength. It is well-known that each person has a specific personality. We will show how different people create passwords of varying strength, based on their personality. Moreover, each participant will be nudged with either a matching or mismatching message based on their personality to change their password creation behavior. This talk will analyze whether the type of message helps improve cybersecurity behavior. Moreover, this talk will also look at the relationship between phishing and personality traits -- what kind of people are less likely to fall victim to phishing attacks? The results of this research can be used to create personalized cybersecurity training which is more effective than current generic cybersecurity training."
        },
        {
            imageSrc: YousungKang,
            title: "Yousung Kang",
            isOnlySpeaker: true,
            description: "Dr. Yousung Kang joined Electronics and Telecommunications Research Institute (ETRI) in 1999, and he is now the Director leading Cryptographic Engineering Research Section. He was a visiting researcher at Queen‚Äôs University Belfast, Northern Ireland, UK, from 2011 to 2012. He obtained his BS and MS in Electronics Engineering from Chonnam National University, Gwangju, Korea in 1997 and in 1999, respectively. He received his Ph.D in Electrical and Electronic Engineering from KAIST, Daejeon, Korea in 2015. His research interests include quantum security, key-hiding technology and applications, side channel analysis, and the areas of cryptographic engineering.",
            talkTitle: "How can we evaluate the quantum security of cryptographic algorithms?",
            abstract: "The security of cryptographic algorithm is generally determined by how much memory and operations are required to attack the algorithm. The quantum security for cryptographic algorithm can be defined in the similar way. The quantum security is determined by how many quantum resources, such as the number of qubits and quantum operations, are required to attack. In this talk, the <Q|Crypton>, ETRI's quantum security evaluation platform for cryptographic algorithms, is introduced. This talk will be helpful in understanding the difference between the theory and practice of quantum security."
        },
    ];

    return (
        <AnimationRevealPage>
            <Header />
            <Container>
                <SingleColumn>

                    <HeadingMain>Special Talk Speakers</HeadingMain>

                    <Content>
                        {cards.map((card, i) => (
                            <div>
                                <Card key={i} reversed={i % 2 === 1}>
                                    {card.isOnlySpeaker && <Image style={{ height: "480px", width: "390px" }} imageSrc={card.imageSrc} />}
                                    {!card.isOnlySpeaker && <BigImage style={{ height: "900px", width: "390px" }} imageSrc={card.imageSrc} />}
                                    <Details>
                                        {/* <Subtitle>{card.subtitle}</Subtitle> */}
                                        <Title>{card.title}</Title>
                                        {card.isOnlySpeaker && <Description>{card.description}</Description>}
                                        {!card.isOnlySpeaker &&
                                            <div>
                                                <Description>
                                                    {card.description1}
                                                </Description>
                                                <Description>
                                                    {card.description2}
                                                </Description>
                                            </div>
                                        }
                                    </Details>
                                </Card>
                                <br />
                                {card.talkTitle && <Heading><HighlightedText>Talk Title </HighlightedText> {card.talkTitle} </Heading>}
                                {card.abstract && <TalkDescription><b>Abstract : </b> <i> {card.abstract} </i></TalkDescription>}
                                {/* <SmallCard>
                                <PresentationTitle>{card.title}</PresentationTitle>
                                <Description>{card.description}</Description>
                            </SmallCard> */}
                            </div>
                        ))}
                    </Content>
                </SingleColumn>
                <SvgDotPattern1 />
                <SvgDotPattern2 />
                <SvgDotPattern3 />
                <SvgDotPattern4 />
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
