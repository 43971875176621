import React from "react";
import tw from "twin.macro";
import Head from "./Head";
import Header from "../../components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter";
import Plans from "./ThreePlans";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as QuotesRightIcon2 } from "../../images/dot-pattern.svg";
import { LinkedinFilled } from '@ant-design/icons';
import { Popover } from "antd";
import styled from "styled-components";
import TabGrid from "../../components/cards/TabCardGrid.js";
import { SectionDescription } from "components/misc/Typography.js";
// const Description = tw(SectionDescription)`w-full text-center`;
const Description = tw.p`mt-4 text-gray-800 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const DecoratorBlob2 = styled(QuotesRightIcon2)`
  ${tw`pointer-events-none fill-current text-primary-500 opacity-25 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-20`}
`;
const HeroContainer = tw.div`z-20 relative max-w-screen-xl mx-auto`;
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
    const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-lg xl:text-lg`;
    const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
    const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;
    const subHead = "2rem"
    return (
      <div>
        <HeroContainer/>
          <AnimationRevealPage>
            <Header />
              <h1 style={{ "color": "#6415FF", fontSize: "2rem", fontWeight: "700", textAlign: "center", marginTop: "30px", marginLeft: "0px" }}>
                Sponsors
              </h1>
            <div style={{ backgroundColor: "rgb(237, 242, 247)" }} className="p-4 mt-4 mb-4">
              <QuoteContainer className="mx-4 p-6" style={{ backgroundColor: "rgb(237, 242, 247)", borderRadius: "5px" }}>
                <Quote>
                  <QuotesLeft />
                  <div style={{ marginTop: "15px", textAlign: "center", textAlign: "justify", textJustify: "interWord" }}>
                    <i>
                      Silicon Valley Cybersecurity Institute (SVCSI) organizes an annual conference,
                      Silicon Valley CybersecurityConference (SVCC). The conference has four tracks: Research Forum, UNiSEC
                      (underrepresented groups in cybersecurity), free student hackathon, and free tutoring sessions. For a
                      successful event, conference sponsorship is crucial to provide our expert knowledge and practices to the
                      community. Your donation will be used for our conference as well as our cybersecurity community at SVCSI, a non-profit organization to develop, investigate, and promote the best security practices for dependable and secure systems and applications. We appreciate your generous donation.
                                          </i>
                  </div>
                  <QuotesRight />
                  <DecoratorBlob2 />
                </Quote>
              </QuoteContainer>
            </div>
            {/* <TabGrid
              heading={
                "Our Sponsors"
              }
            /> */}
            {/* <Description>
              <div className="mt-12" style={{ textAlign: "justify", textJustify: "interWord" }}>
                Silicon Valley Cybersecurity Institute (SVCSI) organizes an annual conference,
                Silicon Valley CybersecurityConference (SVCC). The conference has four tracks: Research Forum, UNiSEC
                (underrepresented groups in cybersecurity), free student hackathon, and free tutoring sessions. For a
                successful event, conference sponsorship is crucial to provide our expert knowledge and practices to the
                community.
            </div>
            </Description> */}
              <Plans />
            <Footer />
          </AnimationRevealPage>
      </div>
    )
  }
}

export default Main;